import { useAxios } from '../useAxios';
import { useMutation } from 'react-query';

const useCreateProgressTracking = () => {
    const axios = useAxios();

    const createProgressTracking = async (pageId) => {
        try {
            const { data } = await axios.post(
                `/progress-tracking/${pageId.page_id}/`,
                {
                    page_id: pageId,
                }
            );
            return data;
        } catch (error) {
            console.error('Error creating progress tracking:', error);
            throw new Error(error.response?.data?.message || error.message);
        }
    };

    return useMutation(createProgressTracking, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.error('Error creating progress tracking:', error);
        },
    });
};

export { useCreateProgressTracking };
