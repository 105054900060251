import { useAxios } from '../../hooks/useAxios';
import { useMutation, useQueryClient } from 'react-query';

const useUpdateUser = () => {
    const axios = useAxios();
    const queryClient = useQueryClient();

    const updateUser = async ({ userID, formValues }) => {
        try {
            const { data } = await axios.put(
                `/admin/user/${userID}/`,
                formValues
            );

            return data;
        } catch (error) {
            console.error('Error updating user information:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(updateUser, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['usersForAdmin'] });
        },
        onError: (error) => {
            console.error('Error updating user information:', error);
        },
    });
};

export { useUpdateUser };
