import axios from 'axios';
import { useMutation } from 'react-query';
import { useUser } from '../../context/userContext';
import { useNavigate } from 'react-router-dom';
const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

const useOAuthLogin = (type) => {
    const { setUser } = useUser();
    const navigate = useNavigate();

    const OAuthLogin = async (values) => {
        try {
            const { data } = await axios.get(
                `${baseUrl}/${type}/callback?${values}`,
                {},
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);

            const { data: user } = await axios.get(
                `${baseUrl}/user/me/`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${data.access_token}`,
                    },
                }
            );
            setUser(user);

            return data;
        } catch (error) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            throw new Error(error.message);
        }
    };

    return useMutation(OAuthLogin, {
        onSuccess: (data) => {
            navigate('/');
        },
    });
};

export { useOAuthLogin };
