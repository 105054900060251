import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import React from "react";
import adminStyles from "./adminPageCss/usersStyles.module.css";
import { useUpdateUser } from './putUsersForAdmin';
import * as Yup from "yup";
import style from "./adminPageCss/leftNavbar.module.css"
import ManageUsersForAdmin from "./ManageUsersForAdmin";
import InstitutionsInterface from "./InstitutionsInterface";
import { useState } from 'react';

export default function AdminInstitutions( {instituts, users} ){
    
    const [addUserInstitut, updateAddUserInstitut] = useState(true);
    const [institutSelected, updateInstitutSelected] = useState("")

    const [addUsersToInst, updateAddUsersToInst ] = useState("");

    const myUpdateAddUserInstitut = (institutSelected) => {
        updateAddUserInstitut(prev => !prev);
        updateInstitutSelected(() => institutSelected);
    }

    return (
        // ako nije kliknut gumb za dodavanje novog korisnika vrati institutions interface,
        // inace vrati interface za dodavanje novog korisnika
      addUserInstitut ? <InstitutionsInterface instituts={instituts} users={users} 
                             myUpdateAddUserInstitut={myUpdateAddUserInstitut} addUsersToInst={addUsersToInst}
                             updateAddUsersToInst = {updateAddUsersToInst}/>

                    : <ManageUsersForAdmin instituts={instituts} users={users} addUserInstitut ={addUserInstitut} 
                                institutSelected={institutSelected} myUpdateAddUserInstitut={myUpdateAddUserInstitut}/>

    );
}