import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useOAuthLogin } from '../hooks';
import { useNavigate } from 'react-router-dom';

const OAuthHandler = ({ type }) => {
    const [searchParams] = useSearchParams();
    const oAuthLogin = useOAuthLogin(type);
    const navigate = useNavigate();

    useEffect(() => {
        oAuthLogin.mutate(window.location.href.split('?')[1], {
            onSuccess: () => {
                navigate('/');
            },
            onError: () => {
                navigate('/login');
            },
        });
    }, []);

    return null;
};
export default OAuthHandler;
