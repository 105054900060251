import { useAxios } from '../useAxios';
import { useQuery } from 'react-query';

const useGetProgressTracking = () => {
    const axios = useAxios();

    const getProgress = async () => {
        try {
            const { data } = await axios.get(`/progress-tracking/`);

            return data;
        } catch (error) {
            console.error('Error fetching progress:', error);
            throw new Error(error.message);
        }
    };

    return useQuery(['profile'], getProgress, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.error('Error fetching progress:', error);
        },
    });
};

export { useGetProgressTracking };
