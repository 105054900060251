import { useAxios } from '../useAxios';
import { useMutation, useQueryClient } from 'react-query';

const useDeleteTest = () => {
    const axios = useAxios();
    const queryClient = useQueryClient();

    const deleteTest = async (id) => {
        try {
            const { data } = await axios.delete(`/test/${id}/`);

            return data;
        } catch (error) {
            console.error('Error delete test', error);
            throw new Error(error.message);
        }
    };

    return useMutation(deleteTest, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['lessons'],
            });
        },
        onError: (error) => {
            console.error('Error delete test', error);
        },
    });
};

export { useDeleteTest };
