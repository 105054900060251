import { useAxios } from '../useAxios';
import { useMutation, useQueryClient } from 'react-query';
import { useCreatePageContent } from './useCreatePageContent';

const useCreatePage = () => {
    const axios = useAxios();
    const queryClient = useQueryClient();
    const createPageContent = useCreatePageContent();

    const createPage = async ({ pageData, pageContentData }) => {
        try {
            const { data } = await axios.post(`/page/`, pageData);

            if (pageContentData) {
                createPageContent.mutate({
                    page_id: data.id,
                    content: pageContentData,
                });
            }

            return data;
        } catch (error) {
            console.error('Error fetching instructor lessons:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(createPage, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['lessons'],
            });
        },
        onError: (error) => {
            console.error('Error fetching instructor lessons:', error);
        },
    });
};

export { useCreatePage };
