import { useAxios } from '../useAxios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const useChatBot = () => {
    const axios = useAxios();
    const navigate = useNavigate();

    const chatbot = async (values) => {
        try {
            const { data } = await axios.get(
                `/chatbot/?message=${values.message}&id=${values.id}`
            );
            return data;
        } catch (error) {
            console.error('Error fetching instructor lessons:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(chatbot, {
        onError: (error) => {
            console.error('Error fetching instructor lessons:', error);
        },
    });
};

export { useChatBot };
