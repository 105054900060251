import React, { useState, useEffect } from 'react';
import { useLinkClickHandler, useParams, useNavigate } from 'react-router-dom';
import {
    useGetLessonsById,
    useUpdatePageContent,
    useUpdateLesson,
    useUpdatePage,
    useCreatePageContent,
    useCreatePage,
    useCreateQuestion,
    useGetLessons,
    useGetLanguages,
    useDeleteLesson,
    useDeletePage,
    useUpdateQuestion,
    useCreateTest,
    useUpdateTest,
    useDeleteTest,
    useUpdateBadge,
    useGetInstitutions,
} from '../../hooks';
import './editInstructorLesson.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import Layout from '../../components/layout/Layout/Layout';
import { Modal, Box } from '@mui/material';

const LessonSchema = Yup.object().shape({
    title: Yup.string()
        .min(1, 'Title is too short')
        .max(50, 'Title is too long')
        .required('Title is required'),
    description: Yup.string()
        .min(1, 'Description is too short')
        .required('Description is required'),
    institution_id: Yup.number()
        .integer('Institution ID must be an integer')
        .required('Institution ID is required'),
});

const PageSchema = Yup.object().shape({
    title: Yup.string()
        .min(1, 'Title must be at least 1 character long')
        .max(50, 'Title cannot exceed 50 characters')
        .required('Title is required'),
});

const QuestionsSchema = Yup.object().shape({
    name: Yup.string()
        .min(1, 'Title must be at least 1 character long')
        .max(50, 'Title cannot exceed 50 characters')
        .required('Title is required'),
    institution_id: Yup.number()
        .integer('Institution ID must be an integer')
        .required('Institution ID is required'),
});

const EditInstructorLesson = () => {
    const { id } = useParams();
    const { data: lesson } = useGetLessonsById(id);
    const [editorValue, setEditorValue] = useState(
        lesson?.pages.map(
            (page) =>
                page.content?.map((content) => content.content || '') ||
                page.questions?.map((content) => content.question_text || '')
        ) || []
    );
    const updatePageContent = useUpdatePageContent();
    const updateLesson = useUpdateLesson();
    const updatePage = useUpdatePage();
    const [newPageData, setNewPageData] = useState({
        title: '',
        content: [
            {
                content: '<p>New page content</p>',
            },
        ],
    });
    const createPage = useCreatePage();
    const [isCreateNewPageOpen, setIsCreateNewPageOpen] = useState(false);
    const [isCreateNewQuestionOpen, setIsCreateNewQuestionOpen] =
        useState(false);
    const [newQuestionText, setNewQuestionText] = useState('');
    const createQuestion = useCreateQuestion();
    const { data: languages } = useGetLanguages();
    const deleteLesson = useDeleteLesson();
    const deletePage = useDeletePage();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedItemToDelete, setSelectedItemToDelete] = useState({
        name: 'first lesson',
        title: 'lesson',
        id: 0,
    });
    const updateQuestion = useUpdateQuestion();
    const navigate = useNavigate();
    const createTest = useCreateTest();
    const updateTest = useUpdateTest();
    const deleteTest = useDeleteTest();
    const updateBadge = useUpdateBadge();
    const { data: institutions } = useGetInstitutions();

    useEffect(() => {
        const newEditorValue = lesson?.pages.map(
            (page) =>
                page.content?.map((content) => content.content || '') ||
                page.questions?.map((content) => content.question_text || '')
        );
        if (JSON.stringify(newEditorValue) !== JSON.stringify(editorValue)) {
            setEditorValue(newEditorValue);
        }
    }, [lesson]);

    if (!lesson || !editorValue || !languages) {
        return <div>Loading...</div>;
    }

    const handleEditorChange = (pageIndex, contentIndex, value) => {
        setEditorValue((prevEditorValue) =>
            prevEditorValue.map((page, index) =>
                index === pageIndex
                    ? page.map((prevValue, idx) =>
                          idx === contentIndex ? value : prevValue
                      )
                    : page
            )
        );
    };

    const savePageContent = (content, pageId, contentId) => {
        updatePageContent.mutate({
            contentId: contentId,
            contentData: content,
            page_id: pageId,
        });
    };

    const initialLessonValues = {
        title: lesson.title,
        description: lesson.description,
        institution_id: lesson.instituion_id,
    };

    const handleLessonSubmit = (values) => {
        updateLesson.mutate({
            lessonId: lesson.id,
            formValues: values,
        });
    };

    const handlePageSubmit = (values, page) => {
        updatePage.mutate({
            pageId: page.id,
            formValues: {
                ...values,
                lesson_id: lesson.id,
                type: page.type,
            },
        });
    };

    const onSubmitNewPage = async (values) => {
        if (newPageData.content.length !== 0) {
            createPage.mutate({
                pageData: {
                    ...values,
                    type: 'gradivo',
                    lesson_id: lesson.id,
                },
                pageContentData: newPageData.content[0].content,
            });
            setIsCreateNewPageOpen(false);
        }
    };

    const onSubmitNewQuestion = async (values) => {
        if (newQuestionText.length !== 0) {
            createPage.mutate(
                {
                    pageData: {
                        title: values.pageTitle,
                        type: 'program',
                        lesson_id: lesson.id,
                    },
                },
                {
                    onSuccess: (page) => {
                        createQuestion.mutate(
                            {
                                ...values,
                                question_text: newQuestionText,
                                page_id: page.id,
                            },
                            {
                                onSuccess: (question) => {
                                    values.tests.forEach((test) => {
                                        createTest.mutate({
                                            ...test,
                                            question_id: question.id,
                                        });
                                    });
                                },
                            }
                        );
                    },
                }
            );
            setIsCreateNewQuestionOpen(false);
        }
    };

    const onPageDelete = (id) => {
        deletePage.mutate({ id });
        setIsDeleteModalOpen(false);
    };

    const handleUpdateQuestion = (values, page) => {
        updatePage.mutate({
            pageId: page.id,
            formValues: {
                title: page.title,
                lesson_id: page.lesson_id,
                type: page.type,
            },
        });
        updateQuestion.mutate({
            ...values.questions[0],
            question_text:
                editorValue[lesson.pages.findIndex((p) => p.id === page.id)][0],
        });
    };

    const onLessonDelete = () => {
        deleteLesson.mutate({ id: lesson.id });
        setIsDeleteModalOpen(false);
        navigate(`/instructor-lessons/`);
    };

    const onSaveTest = (test) => {
        updateTest.mutate(test);
    };

    const onCreateTest = (test, questionId) => {
        createTest.mutate({
            ...test,
            question_id: questionId,
        });
    };

    const onDeleteTest = (id) => {
        deleteTest.mutate(id);
    };

    const handleBadgeUpdate = (values) => {
        updateBadge.mutate({
            ...values,
            id: lesson.badge[0].id,
            lesson_id: lesson.id,
        });
    };

    return (
        <Layout>
            <div className="edit-lesson-page">
                <div className="lesson-data content-container">
                    <h1>Edit lesson</h1>
                    <Formik
                        initialValues={initialLessonValues}
                        validationSchema={LessonSchema}
                        onSubmit={handleLessonSubmit}
                    >
                        {() => (
                            <Form className="form-section">
                                {/* Title Field */}
                                <h2>General info</h2>
                                <div className="input-container">
                                    <label htmlFor="title">Title:</label>
                                    <Field
                                        type="text"
                                        name="title"
                                        placeholder="Enter the lesson title"
                                    />
                                    <ErrorMessage
                                        name="title"
                                        component="div"
                                        className="error"
                                    />
                                </div>

                                {/* Description Field */}
                                <div className="input-container">
                                    <label htmlFor="description">
                                        Description:
                                    </label>
                                    <Field
                                        type="text"
                                        name="description"
                                        placeholder="Enter a description"
                                    />
                                    <ErrorMessage
                                        name="description"
                                        component="div"
                                        className="error"
                                    />
                                </div>

                                {/* Institution ID Field */}
                                <div className="input-container">
                                    <label htmlFor="institution_id">
                                        Institution ID:
                                    </label>
                                    <Field
                                        as="select"
                                        name="institution_id"
                                        className="select-field"
                                    >
                                        <option value="" disabled>
                                            Select an Institution
                                        </option>
                                        {institutions.map((institution) => (
                                            <option
                                                key={institution.id}
                                                value={institution.id}
                                            >
                                                {institution.name}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage
                                        name="institution_id"
                                        component="div"
                                        className="error"
                                    />
                                </div>

                                {/* Submit Button */}
                                <div className="action-buttons">
                                    <div>
                                        <button
                                            type="submit"
                                            className="save-button"
                                        >
                                            Save
                                        </button>
                                    </div>
                                    <button
                                        className="delete-button"
                                        type="submit"
                                        onClick={() => {
                                            setSelectedItemToDelete({
                                                name: lesson.title,
                                                title: 'lesson',
                                                id: lesson.id,
                                            });
                                            setIsDeleteModalOpen(true);
                                        }}
                                    >
                                        Delete lesson
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                    <Formik
                        initialValues={{
                            name: lesson.badge[0].name || '',
                            image_url: lesson.badge[0].image_url || '',
                            lesson_id: lesson.badge[0].lesson_id || '',
                        }}
                        validationSchema={Yup.object({
                            name: Yup.string()
                                .min(1, 'Name must be at least 1 character')
                                .max(50, 'Name must be at most 50 characters')
                                .required('Name is required'),
                            image_url: Yup.string()
                                .url('Must be a valid URL')
                                .min(
                                    1,
                                    'Image URL must be at least 1 character'
                                )
                                .max(
                                    255,
                                    'Image URL must be at most 255 characters'
                                )
                                .required('Image URL is required'),
                        })}
                        onSubmit={(values) => handleBadgeUpdate(values)}
                    >
                        {() => (
                            <Form className="form-section">
                                <h2>Badge</h2>

                                {/* Name Field */}
                                <div className="input-container">
                                    <label htmlFor="name">Name:</label>
                                    <Field
                                        type="text"
                                        name="name"
                                        placeholder="Enter the badge name"
                                    />
                                    <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="error"
                                    />
                                </div>

                                {/* Image URL Field */}
                                <div className="input-container">
                                    <label htmlFor="image_url">
                                        Image URL:
                                    </label>
                                    <Field
                                        type="text"
                                        name="image_url"
                                        placeholder="Enter the image URL"
                                    />
                                    <ErrorMessage
                                        name="image_url"
                                        component="div"
                                        className="error"
                                    />
                                </div>

                                {/* Action Buttons */}
                                <div className="action-buttons">
                                    <div>
                                        <button
                                            type="submit"
                                            className="save-button"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="pages-container content-container">
                    {lesson.pages.map((page, pageIndex) => (
                        <div className="page" key={page.id}>
                            {page.type === 'gradivo' ? (
                                <Formik
                                    initialValues={{
                                        title: page.title,
                                    }}
                                    validationSchema={PageSchema}
                                    onSubmit={(values) =>
                                        handlePageSubmit(values, page)
                                    }
                                    className="page-title"
                                >
                                    {({ values: formValues }) => (
                                        <Form className="form-section">
                                            <div className="input-container">
                                                <label htmlFor="title">
                                                    Title
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter the title"
                                                />
                                                <ErrorMessage
                                                    name="title"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                            {page.content.map(
                                                (content, contentIndex) => (
                                                    <>
                                                        {editorValue &&
                                                        editorValue[
                                                            pageIndex
                                                        ] ? (
                                                            <div className="content">
                                                                <ReactQuill
                                                                    value={
                                                                        editorValue[
                                                                            pageIndex
                                                                        ][
                                                                            contentIndex
                                                                        ]
                                                                    }
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        handleEditorChange(
                                                                            pageIndex,
                                                                            contentIndex,
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        ) : null}
                                                        <div className="action-buttons">
                                                            <button
                                                                className="save-button"
                                                                type="submit"
                                                                onClick={() =>
                                                                    savePageContent(
                                                                        editorValue[
                                                                            pageIndex
                                                                        ][
                                                                            contentIndex
                                                                        ],
                                                                        page.id,
                                                                        content.id
                                                                    )
                                                                }
                                                            >
                                                                Save
                                                            </button>
                                                            <button
                                                                className="delete-button"
                                                                type="button"
                                                                onClick={() => {
                                                                    setSelectedItemToDelete(
                                                                        {
                                                                            name: page.title,
                                                                            title: 'page',
                                                                            id: page.id,
                                                                        }
                                                                    );
                                                                    setIsDeleteModalOpen(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                Delete page
                                                            </button>
                                                        </div>
                                                    </>
                                                )
                                            )}
                                        </Form>
                                    )}
                                </Formik>
                            ) : (
                                <Formik
                                    initialValues={{
                                        title: page.title,
                                        questions: page.questions,
                                    }}
                                    validationSchema={PageSchema}
                                    onSubmit={(values) =>
                                        handleUpdateQuestion(values, page)
                                    }
                                    className="page-title"
                                >
                                    {({ values }) => (
                                        <Form className="form-section">
                                            <div className="input-container">
                                                <label htmlFor="title">
                                                    Title
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter the title"
                                                />
                                                <ErrorMessage
                                                    name="title"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>

                                            <FieldArray name="questions">
                                                {() => (
                                                    <>
                                                        {values.questions.map(
                                                            (
                                                                question,
                                                                questionIndex
                                                            ) => (
                                                                <div
                                                                    className="question"
                                                                    key={
                                                                        questionIndex
                                                                    }
                                                                >
                                                                    {/* Question Fields */}
                                                                    <div className="input-container">
                                                                        <label
                                                                            htmlFor={`questions[${questionIndex}].name`}
                                                                        >
                                                                            Name
                                                                        </label>
                                                                        <Field
                                                                            type="text"
                                                                            name={`questions[${questionIndex}].name`}
                                                                            placeholder="Enter question name"
                                                                        />
                                                                        <ErrorMessage
                                                                            name={`questions[${questionIndex}].name`}
                                                                            component="div"
                                                                            className="error"
                                                                        />
                                                                    </div>

                                                                    <label
                                                                        htmlFor={`questions[${questionIndex}].text`}
                                                                    >
                                                                        Question
                                                                        Text
                                                                    </label>
                                                                    <ReactQuill
                                                                        value={
                                                                            editorValue[
                                                                                pageIndex
                                                                            ][
                                                                                questionIndex
                                                                            ]
                                                                        }
                                                                        onChange={(
                                                                            value
                                                                        ) =>
                                                                            handleEditorChange(
                                                                                pageIndex,
                                                                                questionIndex,
                                                                                value
                                                                            )
                                                                        }
                                                                    />

                                                                    <div className="input-container">
                                                                        <label
                                                                            htmlFor={`questions[${questionIndex}].languages`}
                                                                        >
                                                                            Languages
                                                                        </label>
                                                                        <Field
                                                                            as="select"
                                                                            name={`questions[${questionIndex}].languages`}
                                                                            multiple
                                                                        >
                                                                            {languages.map(
                                                                                (
                                                                                    lang
                                                                                ) => (
                                                                                    <option
                                                                                        key={
                                                                                            lang.id
                                                                                        }
                                                                                        value={
                                                                                            lang.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            lang.name
                                                                                        }
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </Field>
                                                                        <ErrorMessage
                                                                            name={`questions[${questionIndex}].languages`}
                                                                            component="div"
                                                                            className="error"
                                                                        />
                                                                    </div>

                                                                    {/* Tests Section */}
                                                                    <FieldArray
                                                                        name={`questions[${questionIndex}].tests`}
                                                                    >
                                                                        {({
                                                                            push: pushTest,
                                                                            remove: removeTest,
                                                                        }) => (
                                                                            <div className="tests-section">
                                                                                <h2>
                                                                                    Tests
                                                                                </h2>
                                                                                {question.tests.map(
                                                                                    (
                                                                                        test,
                                                                                        testIndex
                                                                                    ) => (
                                                                                        <div
                                                                                            className="test-item"
                                                                                            key={
                                                                                                testIndex
                                                                                            }
                                                                                        >
                                                                                            <h4>
                                                                                                Test{' '}
                                                                                                {testIndex +
                                                                                                    1}
                                                                                            </h4>
                                                                                            <div className="input-container">
                                                                                                <label
                                                                                                    htmlFor={`questions[${questionIndex}].tests[${testIndex}].name`}
                                                                                                >
                                                                                                    Name
                                                                                                </label>
                                                                                                <Field
                                                                                                    type="text"
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].name`}
                                                                                                    placeholder="Enter test name"
                                                                                                />
                                                                                                <ErrorMessage
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].name`}
                                                                                                    component="div"
                                                                                                    className="error"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="input-container">
                                                                                                <label
                                                                                                    htmlFor={`questions[${questionIndex}].tests[${testIndex}].output`}
                                                                                                >
                                                                                                    Output
                                                                                                </label>
                                                                                                <Field
                                                                                                    type="text"
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].output`}
                                                                                                    placeholder="Enter output"
                                                                                                />
                                                                                                <ErrorMessage
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].output`}
                                                                                                    component="div"
                                                                                                    className="error"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="input-container">
                                                                                                <label
                                                                                                    htmlFor={`questions[${questionIndex}].tests[${testIndex}].fail_text`}
                                                                                                >
                                                                                                    Fail
                                                                                                    Text
                                                                                                </label>
                                                                                                <Field
                                                                                                    type="text"
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].fail_text`}
                                                                                                    placeholder="Enter fail text"
                                                                                                />
                                                                                                <ErrorMessage
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].fail_text`}
                                                                                                    component="div"
                                                                                                    className="error"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="input-container">
                                                                                                <label
                                                                                                    htmlFor={`questions[${questionIndex}].tests[${testIndex}].input`}
                                                                                                >
                                                                                                    Input
                                                                                                </label>
                                                                                                <Field
                                                                                                    type="text"
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].input`}
                                                                                                    placeholder="Enter input"
                                                                                                />
                                                                                                <ErrorMessage
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].input`}
                                                                                                    component="div"
                                                                                                    className="error"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="input-container">
                                                                                                <label
                                                                                                    htmlFor={`questions[${questionIndex}].tests[${testIndex}].memory_limit`}
                                                                                                >
                                                                                                    Memory
                                                                                                    Limit
                                                                                                </label>
                                                                                                <Field
                                                                                                    type="number"
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].memory_limit`}
                                                                                                    placeholder="Enter memory limit"
                                                                                                />
                                                                                                <ErrorMessage
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].memory_limit`}
                                                                                                    component="div"
                                                                                                    className="error"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="input-container">
                                                                                                <label
                                                                                                    htmlFor={`questions[${questionIndex}].tests[${testIndex}].time_limit`}
                                                                                                >
                                                                                                    Time
                                                                                                    Limit
                                                                                                </label>
                                                                                                <Field
                                                                                                    type="number"
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].time_limit`}
                                                                                                    placeholder="Enter time limit"
                                                                                                />
                                                                                                <ErrorMessage
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].time_limit`}
                                                                                                    component="div"
                                                                                                    className="error"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="input-container">
                                                                                                <label
                                                                                                    htmlFor={`questions[${questionIndex}].tests[${testIndex}].example`}
                                                                                                >
                                                                                                    Example
                                                                                                </label>
                                                                                                <Field
                                                                                                    type="checkbox"
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].example`}
                                                                                                />
                                                                                                <ErrorMessage
                                                                                                    name={`questions[${questionIndex}].tests[${testIndex}].example`}
                                                                                                    component="div"
                                                                                                    className="error"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="action-buttons">
                                                                                                {!lesson
                                                                                                    .pages[
                                                                                                    pageIndex
                                                                                                ]
                                                                                                    .questions[
                                                                                                    questionIndex
                                                                                                ]
                                                                                                    .tests[
                                                                                                    testIndex
                                                                                                ]
                                                                                                    ?.id ? (
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="save-button"
                                                                                                        onClick={() =>
                                                                                                            onCreateTest(
                                                                                                                test,
                                                                                                                question.id
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        Create
                                                                                                        Test
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="save-button"
                                                                                                        onClick={() =>
                                                                                                            onSaveTest(
                                                                                                                test
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        Save
                                                                                                        Test
                                                                                                    </button>
                                                                                                )}
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="delete-button"
                                                                                                    onClick={() => {
                                                                                                        removeTest(
                                                                                                            testIndex
                                                                                                        );
                                                                                                        if (
                                                                                                            test.id
                                                                                                        ) {
                                                                                                            onDeleteTest(
                                                                                                                test.id
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    Delete
                                                                                                    Test
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                                <button
                                                                                    type="button"
                                                                                    className="save-button"
                                                                                    onClick={() =>
                                                                                        pushTest(
                                                                                            {
                                                                                                name: '',
                                                                                                output: '',
                                                                                                fail_text:
                                                                                                    '',
                                                                                                input: '',
                                                                                                memory_limit:
                                                                                                    '',
                                                                                                time_limit:
                                                                                                    '',
                                                                                                example: false,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Add
                                                                                    Test
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </FieldArray>
                                                                </div>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            </FieldArray>

                                            <div className="action-buttons">
                                                <button
                                                    className="save-button"
                                                    type="submit"
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="delete-button"
                                                    type="button"
                                                    onClick={() => {
                                                        setSelectedItemToDelete(
                                                            {
                                                                name: page.title,
                                                                title: 'page',
                                                                id: page.id,
                                                            }
                                                        );
                                                        setIsDeleteModalOpen(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Delete page
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </div>
                    ))}
                    <div>
                        {!isCreateNewPageOpen ? (
                            <button
                                className="create-page-button save-button"
                                onClick={() => setIsCreateNewPageOpen(true)}
                            >
                                Create new content page
                            </button>
                        ) : (
                            <div className="pages-container content-container">
                                <h1>Create New Page</h1>
                                <div className="page">
                                    <Formik
                                        initialValues={{
                                            title: '', // Ensure initialValues has a title
                                        }}
                                        validationSchema={PageSchema}
                                        onSubmit={(values) => {
                                            onSubmitNewPage(values);
                                        }}
                                        className="page-title"
                                    >
                                        {({ isSubmitting }) => (
                                            <Form className="form-section">
                                                <div className="input-container">
                                                    <label htmlFor="title">
                                                        Title
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="title"
                                                        placeholder="Enter the title"
                                                    />
                                                    <ErrorMessage
                                                        name="title"
                                                        component="div"
                                                        className="error"
                                                    />
                                                </div>

                                                {Array.isArray(
                                                    newPageData.content
                                                ) &&
                                                    newPageData.content.map(
                                                        (
                                                            content,
                                                            contentIndex
                                                        ) => (
                                                            <div
                                                                key={
                                                                    contentIndex
                                                                }
                                                            >
                                                                <div className="content">
                                                                    <ReactQuill
                                                                        value={
                                                                            content.content
                                                                        }
                                                                        onChange={(
                                                                            value
                                                                        ) => {
                                                                            setNewPageData(
                                                                                (
                                                                                    prevData
                                                                                ) => ({
                                                                                    ...prevData,
                                                                                    content:
                                                                                        prevData.content.map(
                                                                                            (
                                                                                                prevContent,
                                                                                                i
                                                                                            ) =>
                                                                                                i ===
                                                                                                contentIndex
                                                                                                    ? {
                                                                                                          content:
                                                                                                              value,
                                                                                                      }
                                                                                                    : prevContent
                                                                                        ),
                                                                                })
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className="action-buttons">
                                                                    <button
                                                                        className="create-page-button save-button"
                                                                        type="submit"
                                                                    >
                                                                        Create
                                                                        content
                                                                        page
                                                                    </button>
                                                                    <button
                                                                        className="create-question-button delete-button"
                                                                        type="button"
                                                                        disabled={
                                                                            isSubmitting
                                                                        }
                                                                        onClick={() => {
                                                                            setIsCreateNewPageOpen(
                                                                                false
                                                                            );
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        {!isCreateNewQuestionOpen ? (
                            <button
                                className="create-page-button save-button"
                                onClick={() => setIsCreateNewQuestionOpen(true)}
                            >
                                Create new question
                            </button>
                        ) : (
                            <div className="questions-container content-container">
                                <h1>Create New Question</h1>
                                <div className="question">
                                    <Formik
                                        initialValues={{
                                            name: '',
                                            question_text: '',
                                            page_id: '',
                                            languages: [],
                                            tests: [
                                                {
                                                    name: '',
                                                    output: '',
                                                    fail_text: '',
                                                    input: '',
                                                    memory_limit: '',
                                                    time_limit: '',
                                                    example: false,
                                                },
                                            ],
                                        }}
                                        validationSchema={Yup.object({
                                            pageTitle: Yup.string()
                                                .min(
                                                    1,
                                                    'Page title must be at least 1 character'
                                                )
                                                .max(
                                                    50,
                                                    'Page title must be at most 50 characters'
                                                )
                                                .required(
                                                    'Page title is required'
                                                ),
                                            name: Yup.string()
                                                .min(
                                                    1,
                                                    'Name must be at least 1 character'
                                                )
                                                .max(
                                                    50,
                                                    'Name must be at most 50 characters'
                                                )
                                                .required('Name is required'),
                                            languages: Yup.array()
                                                .of(
                                                    Yup.number().integer(
                                                        'Language must be an integer'
                                                    )
                                                )
                                                .min(
                                                    1,
                                                    'At least one language is required'
                                                )
                                                .required(
                                                    'Languages are required'
                                                ),
                                            tests: Yup.array().of(
                                                Yup.object().shape({
                                                    name: Yup.string()
                                                        .min(
                                                            1,
                                                            'Name must be at least 1 character'
                                                        )
                                                        .max(
                                                            50,
                                                            'Name must be at most 50 characters'
                                                        )
                                                        .required(
                                                            'Name is required'
                                                        ),
                                                    output: Yup.string()
                                                        .min(
                                                            1,
                                                            'Output must be at least 1 character'
                                                        )
                                                        .required(
                                                            'Output is required'
                                                        ),
                                                    fail_text: Yup.string()
                                                        .min(
                                                            1,
                                                            'Fail text must be at least 1 character'
                                                        )
                                                        .required(
                                                            'Fail text is required'
                                                        ),
                                                    input: Yup.string()
                                                        .min(
                                                            1,
                                                            'Input must be at least 1 character'
                                                        )
                                                        .required(
                                                            'Input is required'
                                                        ),
                                                    memory_limit: Yup.number()
                                                        .min(
                                                            -9223372036854776000,
                                                            'Memory limit is too low'
                                                        )
                                                        .max(
                                                            9223372036854776000,
                                                            'Memory limit is too high'
                                                        )
                                                        .required(
                                                            'Memory limit is required'
                                                        ),
                                                    time_limit: Yup.number()
                                                        .min(
                                                            -9223372036854776000,
                                                            'Time limit is too low'
                                                        )
                                                        .max(
                                                            9223372036854776000,
                                                            'Time limit is too high'
                                                        )
                                                        .required(
                                                            'Time limit is required'
                                                        ),
                                                    example:
                                                        Yup.boolean().required(
                                                            'Example is required'
                                                        ),
                                                })
                                            ),
                                        })}
                                        onSubmit={(values) => {
                                            onSubmitNewQuestion(values);
                                        }}
                                        className="question-form"
                                    >
                                        {({
                                            values,
                                            isSubmitting,
                                            setFieldValue,
                                        }) => (
                                            <Form className="form-section">
                                                {/* Existing Question Form */}
                                                <div className="input-container">
                                                    <label htmlFor="pageTitle">
                                                        Page title
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="pageTitle"
                                                        placeholder="Enter the name"
                                                    />
                                                    <ErrorMessage
                                                        name="pageTitle"
                                                        component="div"
                                                        className="error"
                                                    />
                                                </div>

                                                <div className="input-container">
                                                    <label htmlFor="name">
                                                        Name
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        placeholder="Enter the name"
                                                    />
                                                    <ErrorMessage
                                                        name="name"
                                                        component="div"
                                                        className="error"
                                                    />
                                                </div>

                                                <div>
                                                    <label htmlFor="question_text">
                                                        Question Text
                                                    </label>
                                                    <ReactQuill
                                                        value={newQuestionText}
                                                        onChange={(value) => {
                                                            setNewQuestionText(
                                                                value
                                                            );
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        name="question_text"
                                                        component="div"
                                                        className="error"
                                                    />
                                                </div>

                                                <div className="input-container">
                                                    <label htmlFor="languages">
                                                        Languages
                                                    </label>
                                                    <Field
                                                        as="select"
                                                        name="languages"
                                                        multiple
                                                    >
                                                        {languages.map(
                                                            (lang) => (
                                                                <option
                                                                    key={
                                                                        lang.id
                                                                    }
                                                                    value={
                                                                        lang.id
                                                                    }
                                                                >
                                                                    {lang.name}
                                                                </option>
                                                            )
                                                        )}
                                                    </Field>
                                                    <ErrorMessage
                                                        name="languages"
                                                        component="div"
                                                        className="error"
                                                    />
                                                </div>

                                                {/* New Tests Form */}
                                                <div className="tests-section">
                                                    <h2>Tests</h2>
                                                    <FieldArray name="tests">
                                                        {({ push, remove }) => (
                                                            <div>
                                                                {values.tests.map(
                                                                    (
                                                                        test,
                                                                        index
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="test-item"
                                                                        >
                                                                            <h3>
                                                                                Test{' '}
                                                                                {index +
                                                                                    1}
                                                                            </h3>
                                                                            <div className="input-container">
                                                                                <label
                                                                                    htmlFor={`tests.${index}.name`}
                                                                                >
                                                                                    Name
                                                                                </label>
                                                                                <Field
                                                                                    type="text"
                                                                                    name={`tests.${index}.name`}
                                                                                    placeholder="Enter test name"
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`tests.${index}.name`}
                                                                                    component="div"
                                                                                    className="error"
                                                                                />
                                                                            </div>
                                                                            <div className="input-container">
                                                                                <label
                                                                                    htmlFor={`tests.${index}.output`}
                                                                                >
                                                                                    Output
                                                                                </label>
                                                                                <Field
                                                                                    type="text"
                                                                                    name={`tests.${index}.output`}
                                                                                    placeholder="Enter output"
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`tests.${index}.output`}
                                                                                    component="div"
                                                                                    className="error"
                                                                                />
                                                                            </div>
                                                                            <div className="input-container">
                                                                                <label
                                                                                    htmlFor={`tests.${index}.fail_text`}
                                                                                >
                                                                                    Fail
                                                                                    Text
                                                                                </label>
                                                                                <Field
                                                                                    type="text"
                                                                                    name={`tests.${index}.fail_text`}
                                                                                    placeholder="Enter fail text"
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`tests.${index}.fail_text`}
                                                                                    component="div"
                                                                                    className="error"
                                                                                />
                                                                            </div>
                                                                            <div className="input-container">
                                                                                <label
                                                                                    htmlFor={`tests.${index}.input`}
                                                                                >
                                                                                    Input
                                                                                </label>
                                                                                <Field
                                                                                    type="text"
                                                                                    name={`tests.${index}.input`}
                                                                                    placeholder="Enter input"
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`tests.${index}.input`}
                                                                                    component="div"
                                                                                    className="error"
                                                                                />
                                                                            </div>
                                                                            <div className="input-container">
                                                                                <label
                                                                                    htmlFor={`tests.${index}.memory_limit`}
                                                                                >
                                                                                    Memory
                                                                                    Limit
                                                                                </label>
                                                                                <Field
                                                                                    type="number"
                                                                                    name={`tests.${index}.memory_limit`}
                                                                                    placeholder="Enter memory limit"
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`tests.${index}.memory_limit`}
                                                                                    component="div"
                                                                                    className="error"
                                                                                />
                                                                            </div>
                                                                            <div className="input-container">
                                                                                <label
                                                                                    htmlFor={`tests.${index}.time_limit`}
                                                                                >
                                                                                    Time
                                                                                    Limit
                                                                                </label>
                                                                                <Field
                                                                                    type="number"
                                                                                    name={`tests.${index}.time_limit`}
                                                                                    placeholder="Enter time limit"
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`tests.${index}.time_limit`}
                                                                                    component="div"
                                                                                    className="error"
                                                                                />
                                                                            </div>
                                                                            <div className="input-container">
                                                                                <label
                                                                                    htmlFor={`tests.${index}.example`}
                                                                                >
                                                                                    Example
                                                                                </label>
                                                                                <Field
                                                                                    type="checkbox"
                                                                                    name={`tests.${index}.example`}
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`tests.${index}.example`}
                                                                                    component="div"
                                                                                    className="error"
                                                                                />
                                                                            </div>
                                                                            {values
                                                                                .tests
                                                                                .length >
                                                                            1 ? (
                                                                                <button
                                                                                    type="button"
                                                                                    className="delete-button"
                                                                                    onClick={() => {
                                                                                        remove(
                                                                                            index
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Remove
                                                                                    Test:{' '}
                                                                                    {
                                                                                        test.name
                                                                                    }
                                                                                </button>
                                                                            ) : null}
                                                                        </div>
                                                                    )
                                                                )}
                                                                <button
                                                                    type="button"
                                                                    className="save-button"
                                                                    onClick={() =>
                                                                        push({
                                                                            name: '',
                                                                            output: '',
                                                                            fail_text:
                                                                                '',
                                                                            input: '',
                                                                            memory_limit:
                                                                                '',
                                                                            time_limit:
                                                                                '',
                                                                            example: false,
                                                                        })
                                                                    }
                                                                >
                                                                    Add Test
                                                                </button>
                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                </div>

                                                <div className="action-buttons">
                                                    <button
                                                        className="create-page-button save-button"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        Create question
                                                    </button>
                                                    <button
                                                        className="create-question-button delete-button"
                                                        type="button"
                                                        disabled={isSubmitting}
                                                        onClick={() => {
                                                            setIsCreateNewQuestionOpen(
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                open={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(true)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="delete-modal-container">
                    <div className="delete-modal">
                        <p>
                            Are you shure you want to delete{' '}
                            {selectedItemToDelete.title}:{' '}
                            {selectedItemToDelete.name}
                        </p>
                        <div className="action-buttons">
                            <button
                                className="save-button"
                                onClick={() => setIsDeleteModalOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="delete-button"
                                onClick={() =>
                                    selectedItemToDelete.title === 'lesson'
                                        ? onLessonDelete()
                                        : onPageDelete(selectedItemToDelete.id)
                                }
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Layout>
    );
};

export default EditInstructorLesson;
