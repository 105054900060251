import { createContext, useMemo } from 'react';
import Axios from 'axios';
import { useUser } from './userContext';

const AxiosContext = createContext(Axios);
const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
// const baseUrl = 'https://api.djangopasta.live/api';

const waitForToken = async () => {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            const token = localStorage.getItem('access_token');
            if (token) {
                clearInterval(interval);
                resolve(token);
            }
        }, 100); // Check every 100ms
    });
};

const AxiosContextProvider = ({ children }) => {
    const { setUser } = useUser();

    const getUserInfo = async (token) => {
        const { data: user } = await Axios.get(`${baseUrl}/user/me/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        setUser(user);
    };

    const axiosInstance = useMemo(() => {
        const axios = Axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        axios.interceptors.request.use(
            async (config) => {
                const token = await waitForToken(); // Wait for the token
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        waitForToken().then((token) => {
            if (token) {
                getUserInfo(token);
            }
        });

        return axios;
    }, []);

    return (
        <AxiosContext.Provider value={axiosInstance}>
            {children}
        </AxiosContext.Provider>
    );
};

export { AxiosContext, AxiosContextProvider, baseUrl };
