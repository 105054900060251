import { useAxios } from '../useAxios';
import { useQuery } from 'react-query';

const useGetUserBadgesList = () => {
    const axios = useAxios();

    const getBadges = async () => {
        try {
            const { data } = await axios.get(`/user/badges/`);

            return data;
        } catch (error) {
            console.error('Error fetching badges:', error);
            throw new Error(error.message);
        }
    };

    return useQuery(['badges'], getBadges, {
        onSuccess: (data) => {
            console.log('Badges fetched successfully:', data);
        },
        onError: (error) => {
            console.error('Error fetching badges:', error);
        },
    });
};

export { useGetUserBadgesList };
