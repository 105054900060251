import { useMutation, useQueryClient } from 'react-query';
import { useAxios } from '../useAxios';

const useTestCode = () => {
    const axios = useAxios();
    const queryClient = useQueryClient();
    const runCode = async ({ pageId, formValues }) => {
        try {
            const response = await axios.post(`/tests/run/`, {
                question_id: formValues.question_id,
                language_id: formValues.language_id,
                code: formValues.code,
            });
            return response.data;
        } catch (error) {
            console.log('Error running code:', formValues);
            console.error('Error running code:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(runCode, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['tests'],
            });
            console.log('Code run successfully:', data);
        },
        onError: (error) => {
            console.error('Error running code:', error);
        },
    });
};

export { useTestCode };
