import { useMutation, useQueryClient } from 'react-query';
import { useAxios } from '../../hooks/useAxios';

const useDeleteUser = () => {
    const axios = useAxios();
    const queryClient = useQueryClient();

    const deleteUser = async (userID) => {
        try {
            const { data } = await axios.delete(`/admin/user/${userID}/`);
            return data;
        } catch (error) {
            console.error('Error deleting user:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(deleteUser, {
        onSuccess: () => {
            queryClient.invalidateQueries(['usersForAdmin']);
        },
        onError: (error) => {
            console.error('Error deleting user:', error);
        },
    });
};

export { useDeleteUser };
