import React from 'react';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useUser } from '../../../context/userContext';
import { Link, useNavigate } from 'react-router-dom';

import navStyle from './NavigationBar.module.css';
import navBarAnim from './NavBarAnimation.module.css';
import { Chatbot } from '../../chatbot/Chatbot';

//kom
const Header = ({ children }) => {
    const { user } = useUser();
    const navigate = useNavigate();

    const getProfileLink = () => {
        if (user && user.role === 'ucenik') {
            return '/profile';
        }
        return '/';
    };

    const logout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        navigate('/login');
    };

    return (
        <nav
            className={`navbar navbar-expand-sm ${navStyle.navHeight}`}
            style={{ backgroundColor: '#1c2331' }}
        >
            <div className="container-fluid">
                <div>
                    {user && (
                        <>
                            <Link
                                className="navbar-brand text-info"
                                to="/profile"
                                id=""
                            >
                                <img
                                    className={`${navBarAnim.PopUp}`}
                                    src="https://picsum.photos/200/300"
                                    width={60}
                                    height={60}
                                    style={{ borderRadius: '50px' }}
                                    alt=""
                                />
                                <span
                                    className={`ms-4 fs-3 fw-bold ${navBarAnim.PopUp}`}
                                >
                                    {user?.username}
                                </span>
                            </Link>
                            <span
                                className={navStyle.logoutButton}
                                onClick={logout}
                            >
                                Logout
                            </span>
                        </>
                    )}

                    {!user && (
                        <div className={`${navStyle.navLoginLinks}`}>
                            <Link
                                to="/login"
                                className={`navbar-brand btn m-2`}
                            >
                                Prijavi se
                            </Link>
                            <Link
                                to="/register"
                                className={`navbar-brand btn btn-danger m-0`}
                            >
                                Registriraj se
                            </Link>
                        </div>
                    )}
                </div>

                <button
                    className="navbar-toggler bg-info"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                {user && user.role === 'ucenik' && (
                    <div
                        className="collapse navbar-collapse"
                        id="navbarNavAltMarkup"
                    >
                        <ul className="navbar-nav ms-auto pe-5">
                            <li
                                className={`nav-item fs-3 fw-bold me-4 ${navBarAnim.slideUp_default} ${navBarAnim.navItems}`}
                            >
                                <Link
                                    className="nav-link text-info active"
                                    aria-current="page"
                                    to="/"
                                >
                                    Home
                                </Link>
                            </li>
                            <li
                                className={`nav-item fs-3 fw-bold me-4 ${navBarAnim.slideUp_default} ${navBarAnim.slideUp_1} ${navBarAnim.navItems}`}
                            >
                                <Link
                                    className="nav-link text-info"
                                    to="/lessons"
                                >
                                    Lessons
                                </Link>
                            </li>
                        </ul>
                    </div>
                )}

                {user && user.role === 'instructor' && (
                    <div
                        className="collapse navbar-collapse"
                        id="navbarNavAltMarkup"
                    >
                        <ul className="navbar-nav ms-auto pe-5">
                            <li
                                className={`nav-item fs-3 fw-bold me-4 ${navBarAnim.slideUp_default} ${navBarAnim.navItems}`}
                            >
                                <Link
                                    className="nav-link text-info active"
                                    aria-current="page"
                                    to="/"
                                >
                                    Home
                                </Link>
                            </li>
                            <li
                                className={`nav-item fs-3 fw-bold me-4 ${navBarAnim.slideUp_default} ${navBarAnim.navItems}`}
                            >
                                <Link
                                    className="nav-link text-info active"
                                    aria-current="page"
                                    to="/lessons"
                                >
                                    Lessons
                                </Link>
                            </li>
                            <li
                                className={`nav-item fs-3 fw-bold me-4 ${navBarAnim.slideUp_default} ${navBarAnim.slideUp_1} ${navBarAnim.navItems}`}
                            >
                                <Link
                                    className="nav-link text-info"
                                    to="/instructor-lessons"
                                >
                                    Instructor lessons
                                </Link>
                            </li>
                        </ul>
                    </div>
                )}

                {user && user.role === 'admin' && (
                    <div
                        className="collapse navbar-collapse"
                        id="navbarNavAltMarkup"
                    >
                        <ul className="navbar-nav ms-auto pe-5">
                            <li
                                className={`nav-item fs-3 fw-bold me-4 ${navBarAnim.slideUp_default} ${navBarAnim.navItems}`}
                            >
                                <Link
                                    className="nav-link text-info active"
                                    aria-current="page"
                                    to="/"
                                >
                                    Home
                                </Link>
                            </li>
                            <li
                                className={`nav-item fs-3 fw-bold me-4 ${navBarAnim.slideUp_default} ${navBarAnim.navItems}`}
                            >
                                <Link
                                    className="nav-link text-info active"
                                    aria-current="page"
                                    to="/adminDashboard"
                                >
                                    Admin
                                </Link>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </nav>
    );
};
export default Header;
