import { useAxios } from '../useAxios';
import { useQuery } from 'react-query';

const useGetLessons = () => {
    const axios = useAxios();

    const getLesson = async () => {
        try {
            const { data } = await axios.get(`/lesson/`);

            return data;
        } catch (error) {
            console.error('Error fetching instructor lessons:', error);
            throw new Error(error.message);
        }
    };

    return useQuery(['lessons'], getLesson, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.error('Error fetching instructor lessons:', error);
        },
    });
};

export { useGetLessons };
