import React, { useState } from 'react';
import {
    useGetInstructorLessons,
    useCreateLesson,
    useGetInstitutions,
} from '../../hooks';
import './instructorLessons.scss';
import { Link } from 'react-router-dom';
import { Modal, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Layout from '../../components/layout/Layout/Layout';

const InstructorLessons = () => {
    const { data: lessons } = useGetInstructorLessons();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const createLesson = useCreateLesson();
    const { data: institutions } = useGetInstitutions();

    if (!lessons) {
        return <div>Loading...</div>;
    }

    return (
        <Layout>
            <div className="instructor-lessons-page content-container">
                <div className="title-container">
                    <h1>My Lessons</h1>
                    <button onClick={() => setIsCreateModalOpen(true)}>
                        Create new lesson
                    </button>
                </div>
                <div className="lessons-list-container">
                    {lessons.map((lesson) => (
                        <Link
                            to={`/instructor-lessons/${lesson.id}`}
                            className="lesson"
                            key={lesson.id}
                        >
                            <p>{lesson.title}</p>
                        </Link>
                    ))}
                </div>
                <Modal
                    open={isCreateModalOpen}
                    onClose={() => setIsCreateModalOpen(false)}
                    className="modal-container"
                >
                    <div className="create-lesson-modal">
                        <h2>Create New Lesson</h2>
                        <LessonForm
                            initialValues={{
                                title: '',
                                description: '',
                                instituion_id: '',
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                createLesson.mutate({
                                    ...values,
                                    instituion_id: parseInt(
                                        values.instituion_id
                                    ),
                                });
                                setIsCreateModalOpen(false);
                                setSubmitting(false);
                            }}
                            institutions={institutions}
                        />
                    </div>
                </Modal>
            </div>
        </Layout>
    );
};

const LessonForm = ({ initialValues, onSubmit, institutions }) => {
    // Validation Schema
    const LessonSchema = Yup.object().shape({
        title: Yup.string()
            .min(1, 'Title must be at least 1 character long')
            .max(50, 'Title cannot exceed 50 characters')
            .required('Title is required'),
        description: Yup.string()
            .min(1, 'Description must be at least 1 character long')
            .required('Description is required'),
        instituion_id: Yup.number()
            .typeError('Institution ID must be a number')
            .integer('Institution ID must be an integer')
            .required('Institution ID is required'),
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={LessonSchema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    {/* Title Field */}
                    <div className="input-container">
                        <label htmlFor="title">Title</label>
                        <Field
                            name="title"
                            type="text"
                            placeholder="Enter title"
                        />
                        <ErrorMessage
                            name="title"
                            component="div"
                            className="error"
                        />
                    </div>

                    {/* Description Field */}
                    <div className="input-container">
                        <label htmlFor="description">Description</label>
                        <Field
                            name="description"
                            as="textarea"
                            placeholder="Enter description"
                        />
                        <ErrorMessage
                            name="description"
                            component="div"
                            className="error"
                        />
                    </div>

                    {/* Institution ID Field */}
                    <div className="input-container">
                        <label htmlFor="instituion_id">Institution</label>
                        <Field
                            as="select"
                            name="instituion_id"
                            className="select-field"
                        >
                            <option value="" disabled>
                                Select an Institution
                            </option>
                            {institutions.map((institution) => (
                                <option
                                    key={institution.id}
                                    value={institution.id}
                                >
                                    {institution.name}
                                </option>
                            ))}
                        </Field>
                        <ErrorMessage
                            name="institution_id"
                            component="div"
                            className="error"
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="save-button"
                    >
                        {isSubmitting ? 'Submitting...' : 'Create Lesson'}
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default InstructorLessons;
