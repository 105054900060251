import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import React from "react";
import adminStyles from "./adminPageCss/usersStyles.module.css";
import * as Yup from "yup";
import style from "./adminPageCss/leftNavbar.module.css"

export default function AddUsersToInst( {users, updateAddUsersToInst, addUsersToInst} ){

    return(

        <div className={`col-10 overflow-y-auto mb-5 ${style.usersBackgroundColor} fw-bold`}>
            <h1 className="text-center">{addUsersToInst}</h1>
            <div className="prikaziInstitucije p-2 d-flex flex-column overflow-y-auto">
                {users.filter(user => !user.institutions.includes(addUsersToInst)).map((user) => (

                        <div className={`d-flex justify-content-around text-center align-items-center ${adminStyles.heightUsers}`} style={{border: "1.4px #BDC3C7  solid"}} key={`userID-${user.id}`}>
                            <div><span>Username: {user.username || "Nepoznato"}</span></div>
                            <div><span>Ime: {user.first_name || "Nepoznato"}</span></div>
                            <div>Prezime: {user.last_name || "Nepoznato"}</div>

                            <button className="btn bg-info"> {/*onclick add user to that inst*/}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-plus" viewBox="0 0 16 16">
                                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5V6H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V7H6a.5.5 0 0 1 0-1h1.5V4.5A.5.5 0 0 1 8 4"/>
                            </svg>
                                <span className="ms-1">Dodaj</span>
                            </button>
                        </div>
                ))}

            <div className="d-flex">
                <button className="btn btn-primary mt-5" onClick={() => updateAddUsersToInst("")} style={{width: "14%"}}>
                    <span>Izađi</span>
                </button>
            </div>

            </div>
        </div>



    )

}