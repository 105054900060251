import { Formik, useFormik, Field, Form, ErrorMessage } from 'formik';
import React from 'react';
import adminStyles from './adminPageCss/usersStyles.module.css';
import { useUpdateUser } from './putUsersForAdmin';
import * as Yup from 'yup';
import { useDeleteUser } from './useDeleteUser';
import { Select } from '@mui/material';

export default function AdminUsers({ users, instituts }) {
    const updateUser = useUpdateUser();

    const deleteUser = useDeleteUser();

    const userSubmitSchema = Yup.object().shape({
        email: Yup.string()
            .email('Netočan email format')
            .required('Potrebno je unijeti email!'),
        first_name: Yup.string()
            .min(3, 'Ime mora sadržavati barem 3 slova!')
            .matches(/^[a-zA-Z]+$/, 'U imenu su dozovljena samo slova!')
            .required('Potrebno je unijeti ime!'),
        last_name: Yup.string()
            .min(3, 'Prezime mora sadržavati barem 3 slova!')
            .matches(/^[a-zA-Z]+$/, 'U prezimenu su dozovljena samo slova!')
            .required('Potrebno je unijeti ime!'),
        username: Yup.string()
            .min(3, 'Korisničko ime mora sadržavati barem 3 znaka!')
            .required('Korisnik mora imati korisničko ime!'),
        id: Yup.string().required('Korisnik mora imati definiran ID!'),
    });

    const options = instituts.map((institut) => ({
        value: institut.id,
        label: institut.name,
    }));

    return (
        <div
            className={`col-10 overflow-y-auto mb-5 ${adminStyles.usersBackgroundColor}`}
        >
            <h1 className="text-center mt-3 mb-3">Korisnici</h1>
            <div className="prikaziKorisnike p-2 d-flex flex-column">
                {users.map((user) => (
                    <div
                        key={user.id}
                        className="fw-bold p-1 ps-4"
                        style={{ border: '1.4px #BDC3C7  solid' }}
                    >
                        {/* Glavni red */}
                        <div
                            className={`${adminStyles.heightUsers} d-flex align-items-center`}
                        >
                            <div className="w-25">
                                <span>
                                    Korisničko ime:{' '}
                                    {user.username
                                        ? user.username
                                        : 'Nepoznato'}
                                </span>
                            </div>
                            <div className="w-25">
                                <span>
                                    Uloga: {user.role ? user.role : 'Nepoznato'}
                                </span>
                            </div>
                            <div className="w-25">
                                <span>
                                    ID: {user.id ? user.id : 'Nepoznato'}
                                </span>
                            </div>
                            <div className="w-25">
                                <button
                                    className="btn bg-primary "
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-${user.id}`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-arrow-down-circle-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                                    </svg>
                                    <span> More</span>
                                </button>

                                <button
                                    className="btn bg-success"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-${user.id}-${user.id}`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-journal-arrow-up"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M8 11a.5.5 0 0 0 .5-.5V6.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 6.707V10.5a.5.5 0 0 0 .5.5"
                                        />
                                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                                    </svg>
                                    <span> Update</span>
                                </button>


                                <button className="btn bg-danger" data-bs-toggle="modal" data-bs-target={`#deleteUserConfirm-${user.id}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                    </svg>
                                    <span>Delete</span>

                                </button>
                            </div>
                        </div>

                        <div
                            className="modal fade"
                            id={`deleteUserConfirm-${user.id}`}
                            tabIndex="-1"
                            aria-labelledby="deleteUserModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title text-danger" id="deleteUserModalLabel">
                                            Izbriši korisnika
                                        </h4>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body"><h4 className="text-dark">Jeste li sigurni da želite izbrisati korisnika?</h4></div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                            Povratak
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger" data-bs-dismiss="modal"
                                            onClick={() => deleteUser.mutate(user.id)}
                                        >
                                            Izbriši
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Za prikazati vise */}
                        <div className="collapse" id={`collapse-${user.id}`}>
                            <div
                                className="d-flex flex-column p-3 w-75"
                                style={{
                                    backgroundColor: '#34495E',
                                    color: '#E8F6F3 ',
                                }}
                            >
                                <div style={{ color: 'cde135' }}>
                                    <span>
                                        Ime:{' '}
                                        {user.first_name
                                            ? user.first_name
                                            : 'Nepoznato'}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        Prezime:{' '}
                                        {user.last_name
                                            ? user.last_name
                                            : 'Nepoznato'}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        Email:{' '}
                                        {user.email ? user.email : 'Nepoznato'}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* Za update podatke */}
                        <div
                            className="collapse"
                            id={`collapse-${user.id}-${user.id}`}
                            style={{ backgroundColor: 'df871e' }}
                        >
                            <Formik
                                initialValues={{
                                    email: `${user.email}`,
                                    username: `${user.username}`,
                                    first_name: `${user.first_name}`,
                                    last_name: `${user.last_name}`,
                                    id: `${user.id}`,
                                    role: `${user.role}`,
                                    institutions: user.institutions,
                                }}
                                validationSchema={userSubmitSchema}
                                onSubmit={(values) => {
                                    updateUser.mutate({
                                        userID: user.id,
                                        formValues: values,
                                    });
                                    console.log('user info updated', values);
                                }} // Define put request logic
                            >
                                {() => (
                                    <Form>
                                        <div className="d-flex flex-column p-3 w-75">
                                            {/* Username */}
                                            <div className="mb-1">
                                                <label htmlFor={`username-${user.id}`}>Korisničko ime:</label>
                                                <Field
                                                    type="text"
                                                    id={`username-${user.id}`}
                                                    name="username"
                                                    className="form-control"
                                                    placeholder={user.username || 'Nepoznato'}
                                                />
                                                <ErrorMessage
                                                    name="username"
                                                    component="div"
                                                    className="ms-1 mt-2 fw-bold text-danger"
                                                />
                                            </div>

                                            {/* First Name */}
                                            <div className="mb-1">
                                                <label htmlFor={`first_name-${user.id}`}>Ime:</label>
                                                <Field
                                                    type="text"
                                                    id={`first_name-${user.id}`}
                                                    name="first_name"
                                                    className="form-control"
                                                    placeholder={user.first_name || 'Nepoznato'}
                                                />
                                                <ErrorMessage
                                                    name="first_name"
                                                    component="div"
                                                    className="ms-1 mt-2 fw-bold text-danger"
                                                />
                                            </div>

                                            {/* Last Name */}
                                            <div className="mb-1">
                                                <label htmlFor={`last_name-${user.id}`}>Prezime:</label>
                                                <Field
                                                    type="text"
                                                    id={`last_name-${user.id}`}
                                                    name="last_name"
                                                    className="form-control"
                                                    placeholder={user.last_name || 'Nepoznato'}
                                                />
                                                <ErrorMessage
                                                    name="last_name"
                                                    component="div"
                                                    className="ms-1 mt-2 fw-bold text-danger"
                                                />
                                            </div>

                                            {/* Email */}
                                            <div className="mb-1">
                                                <label htmlFor={`email-${user.id}`}>Email:</label>
                                                <Field
                                                    type="email"
                                                    id={`email-${user.id}`}
                                                    name="email"
                                                    className="form-control"
                                                    placeholder={user.email || 'Nepoznato'}
                                                />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    className="ms-1 mt-2 fw-bold text-danger"
                                                />
                                            </div>

                                            {/* Institutions */}
                                            <div className="d-flex flex-column p-3 w-50">
                                                <label htmlFor={`institutions-${user.id}`}>
                                                    Pridijeli institucije:
                                                </label>
                                                <Field as="select" name="institutions" multiple>
                                                    {instituts.map((institut) => (
                                                        <option
                                                            key={institut.id}
                                                            value={institut.id}
                                                            name={institut.name}
                                                            id={institut.id}
                                                        >
                                                            {institut.name}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>

                                            {/* Submit Button */}
                                            <button type="submit" className="btn btn-primary ms-5 w-25">
                                                Update
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
