import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import React, { useState } from "react";
import adminStyles from "./adminPageCss/usersStyles.module.css";
import { useUpdateUser } from './putUsersForAdmin';
import * as Yup from "yup";
import style from "./adminPageCss/leftNavbar.module.css"
import ManageUsersForAdmin from "./ManageUsersForAdmin";
import AddUsersToInst from "./AddUsersToInst";
import { useCreateInstitution } from "./createInstitutionForAdmin";

export default function InstitutionsInterface( {instituts, users, myUpdateAddUserInstitut, updateAddUsersToInst, addUsersToInst} ){
    
    const addInstitut = useCreateInstitution();

    const countUsersForInstitut = (institutName) => {
        let cnt = 0; 
        for (let user of users) {
            if (user.institutions.includes(institutName)) { 
                cnt++;
            }
        }
        return cnt;
    };
    
    const userEmailUpdate = Yup.object().shape({
        email: Yup.string().email("Netočan email format")
                            .min(3, "Novi mail mora imati barem 3 znaka!")
                            .required("Potrebno je unijeti email!")});

    return (
        
        <>
        {addUsersToInst === "" ?

        <div className={`col-10 overflow-y-auto mb-5 ${style.usersBackgroundColor} fw-bold`}>
            <h1 className="text-center mt-3 mb-3">Institucije</h1>

            <div>
                <button className="bg-danger btn ms-3 mb-2 fs-5" data-bs-toggle="modal" data-bs-target="#addInstitution12345">
                    Dodaj institucije
                </button>
            </div>

            <div className="modal" id="addInstitution12345" role="dialog">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title text-primary fs-3">Dodaj instituciju</h5>
                  </div>
                  <div className="modal-body"> {/* modal body */}
                    
                  <Formik initialValues={{
                    image_url: "",
                    email: "",
                    name: "",
                    website_url: "",
                  }}

                    onSubmit={(values) => {console.log("datttta", values); addInstitut.mutate(values)}} // napisati put req
                    >
                    <Form>

                        <label className="text-dark fs-4 p-1 mb-2" htmlFor="">Unesite ime institucije
                        <Field type="name" name="name" placeholder="Ime institucije"/>
                        </label>
                        <label className="text-dark fs-4 p-1 mb-2" htmlFor="">Unesite email institucije
                        <Field type="text" name="email" placeholder="Email institucije"/>
                        </label>
                        <label className="text-dark fs-4 p-1 mb-2" htmlFor="">Unesite image URL institucije
                        <Field type="text" name="image_url" placeholder="Image URL institucije"/>
                        </label>
                        <label className="text-dark fs-4 p-1" htmlFor="">Unesite web url institucije
                        <Field type="text" name="website_url" placeholder="Web url institucije"/>
                        </label>

                       <div className="mt-4 ms-3" data-bs-dismiss="modal">
                        <button type="submit" className="btn bg-primary fs-4 w-25">
                            Dodaj
                        </button>
                       </div>

                    </Form>
                    </Formik>

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger fs-4" data-bs-dismiss="modal">Zatvori</button>
                  </div>
                </div>
              </div>
            </div>



            <div className="prikaziInstitucije p-2 d-flex flex-column overflow-y-auto">
                {instituts.map((institut) => (
                    <div key={institut.id} className="d-flex nowrap container mb-5 border">
                        <div className="col-7 d-flex flex-column">
                            <h2 className="text-center mt-3">{institut.name}</h2>
                            <hr className="w-100 text-warning"/>

                            <div className="h-100 d-flex flex-column justify-content-around">
                            <h5 className="mb-3 mt-2">Email: {institut.email}

                            {/*
                                <button className="btn bg-info ms-3" data-bs-toggle="collapse" data-bs-target={`#collapse-${institut.id}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-arrow-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 11a.5.5 0 0 0 .5-.5V6.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 6.707V10.5a.5.5 0 0 0 .5.5"/>
                                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
                                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
                                    </svg>
                                    <span> Izmijeni</span>
                                </button>
                             */}

                            </h5>

                            <div className="collapse" id={`collapse-${institut.id}`}>
                                <Formik 
                                initialValues={{
                                    email: ""
                                }}
                                validationSchema={userEmailUpdate}
                                onSubmit={(values) => console.log("Submitted new mail", values)}
                                >
                                    <Form>
                                        <Field
                                        type="text"
                                        id={`institut-${institut.id}`}
                                        name="email"
                                        className="form-control w-50 mb-2"
                                        placeholder={institut.email || "Nepoznato"}>

                                        </Field>
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="ms-1 mt-2 fw-bold text-danger"
                                        />


                                            <button type="submit" className="btn btn-primary w-25 mb-2">
                                                Update
                                            </button>
                                    </Form>
                                </Formik>
                            </div>

                            <h4 className="mb-3">Broj korisnika: {countUsersForInstitut(institut.id)} </h4>
                           
                            <div>
                                <button className="btn btn-primary" onClick={() => myUpdateAddUserInstitut(institut.id)}>
                                    <span>Prikaži korisnike</span>
                                </button>
                            </div>

                        {/*
                            <div>
                            <button className="btn btn-primary" onClick={() => updateAddUsersToInst(() => institut.id)}>
                                Dodaj korisnike
                            </button>
                            </div>
                         */}
                            
                            </div>
                        </div>


                        <div className="col-5">
                            <img className="object-fit-contain h-100 w-100" src="https://external-preview.redd.it/ftAy-UySZ1EpqPt1iOyuBvLy5NWxSxqenkrQ1p1C-fQ.jpg?auto=webp&s=391370875cd9ddb2b5d3ad95453edf41bd9df576" alt="Institucija slika" />
                        </div>


                    </div>
                ))}

            </div>
        </div>

        : <AddUsersToInst updateAddUsersToInst={updateAddUsersToInst} addUsersToInst={addUsersToInst}/>
        }
    </>                  
    );
}