import React from 'react';
import style from "./adminPageCss/leftNavbar.module.css"

export default function AdminSidebar( {updateUsersMode} ) {

    return (
                <div className={`col-auto col-2 px-sm-2 px-0 h-100 ${style.sideBarColor} text-center`}>
                    <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white h-100 justify-content-between">

                        <div className='col-12 h-25 text-center mt-4'>
                            <h2 className={`m-0 ${style.adminDashBoard}`}>Admin dashboard</h2>
                            <hr className='text-warning'/>
                        </div>

                        <div className='col-12 h-75 mt-5'>
                        <div className='d-flex justify-content-center'>
                                    <button className="nav-link px-0 align-middle fw-bold fs-4 text-center" onClick={() => updateUsersMode(true)}>
                                        <span className={`ms-1 d-none d-sm-inline text-info ${style.hoverText}`}>KORISNICI</span>
                                    </button>
                                </div>

                            <hr />
                             
                                <div className='d-flex justify-content-center'>
                                    <button  className="nav-link px-0 align-middle fw-bold fs-4 text-center" onClick={() => updateUsersMode(false)}>
                                        <span className={`ms-1 d-none d-sm-inline text-center text-info ${style.hoverText}`}>INSTITUCIJE</span>
                                    </button>
                                </div>

                        </div>

                    </div>
                </div>
    );
}
