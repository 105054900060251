import { useAxios } from '../useAxios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const useChatBotInitially = () => {
    const axios = useAxios();
    const navigate = useNavigate();

    const chatbotInitially = async (values) => {
        try {
            const { data } = await axios.get(
                `/chatbot/?message='${values.message}'`
            );
            return data;
        } catch (error) {
            console.error('Error fetching instructor lessons:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(chatbotInitially, {
        onError: (error) => {
            console.error('Error fetching instructor lessons:', error);
        },
    });
};

export { useChatBotInitially };
