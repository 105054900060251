import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { useGetLessons } from '../../hooks';
import Layout from '../../components/layout/Layout/Layout';
import './lessons.scss';

//description: "desc" id: 1 instituion_id: 1 title: "newLesson1"

const Lessons = () => {
    const { data: lesson } = useGetLessons();
    const [search, setSearch] = useState('');

    if (!lesson) {
        return <p>Loading...</p>;
    }

    //console.log("ddd" ,lesson)

    return (
        <Layout>
            <div className="container-fluid mt-4 content-container lessons-page">
                <div className="row mb-5">
                    <div className="search-container ">
                        <p>Search: </p>
                        <input
                            className="form-control me-2"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>

                <div className="lessons-container">
                    {lesson
                        .filter((lesson) => lesson.title.includes(search))
                        .map((lesson) => (
                            <div key={lesson.id}>
                                <div className="card-body text-center">
                                    <h5 className="card-title">
                                        {lesson.title}
                                    </h5>
                                    <p className="card-text">
                                        {lesson.description}
                                    </p>
                                    <Link
                                        to={`/lessons/${lesson.id}`}
                                        state={lesson}
                                        className="btn"
                                    >
                                        Learn More
                                    </Link>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </Layout>
    );
};

export default Lessons;
