import { useAxios } from '../useAxios';
import { useMutation, useQueryClient } from 'react-query';

const useDeletePage = () => {
    const axios = useAxios();
    const queryClient = useQueryClient();

    const deletePage = async ({ id }) => {
        try {
            const { data } = await axios.delete(`/page/${id}/`);

            return data;
        } catch (error) {
            console.error('Error deleting page:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(deletePage, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['lessons'],
            });
        },
        onError: (error) => {
            console.error('Error deleting lessons:', error);
        },
    });
};

export { useDeletePage };
