import { useAxios } from '../useAxios';
import { useQuery } from 'react-query';

const useGetInstitutions = () => {
    const axios = useAxios();

    const getInstitutions = async () => {
        try {
            const { data } = await axios.get(`/institutions/`);

            return data;
        } catch (error) {
            console.error('Error fetching institutions:', error);
            throw new Error(error.message);
        }
    };

    return useQuery(['institutions'], getInstitutions, {
        onSuccess: (data) => {
            console.log('Data fetched successfully:', data);
        },
        onError: (error) => {
            console.error('Error fetching institutions:', error);
        },
    });
};

export { useGetInstitutions };
