import { useAxios } from '../useAxios';
import { useMutation } from 'react-query';

const useStartLesson = () => {
    const axios = useAxios();

    const startLesson = async (lessonId) => {
        try {
            //console.log('pageId:', lessonId);
            const { data } = await axios.post(
                `/start-lesson/${lessonId.lesson_id}/`,
                {
                    lesson_id: lessonId,
                }
            );
            return data;
        } catch (error) {
            console.error('Error creating progress tracking:', error);
            throw new Error(error.response?.data?.message || error.message);
        }
    };

    return useMutation(startLesson, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.error('Error creating progress tracking:', error);
        },
    });
};

export { useStartLesson };
