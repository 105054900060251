import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
    useGetLessonsById,
    useStartLesson,
    useCreateProgressTracking,
    useGetProgressTracking,
} from '../../hooks';
import Layout from '../../components/layout/Layout/Layout';

const ChosenLesson = () => {
    const { id: lessonID } = useParams();
    const { data: lesson } = useGetLessonsById(lessonID);
    const { data: progressTracking } = useGetProgressTracking();
    const startLesson = useStartLesson();
    const createProgressTracking = useCreateProgressTracking();
    const navigate = useNavigate();

    if (!lesson) {
        return <p>Loading...</p>;
    }

    const lastVisitedPage = progressTracking?.find(
        (progress) => progress.lesson_id == lessonID
    )?.last_page_id;

    const lastProgress = progressTracking?.find(
        (progress) => progress.lesson_id == lessonID
    )?.progress;

    const handleStartLesson = () => {
        startLesson.mutate(
            { lesson_id: lessonID },
            {
                onSuccess: () => {
                    navigate(`/lessons/${lessonID}/${lesson.pages[0].id}`);
                },
                onError: (error) => {
                    console.error('Error starting lesson:', error);
                },
            }
        );
        createProgressTracking.mutate(
            { page_id: lesson.pages[0].id },
            {
                onError: (error) => {
                    console.error('Error updating progress tracking:', error);
                },
            }
        );
    };

    const handleContinueLesson = () => {
        navigate(`/lessons/${lessonID}/${lastVisitedPage}`);
    };

    const handleRestartLesson = () => {
        if (!window.confirm('Are you sure? You will lose your progress.')) {
            return;
        }

        handleStartLesson();
    };

    return (
        <Layout>
            <div className="d-flex flex-column align-items-center current-lesson">
                <div className="container mt-5 general-info">
                    <div className="row">
                        <p>
                            Lesson title: <span>{lesson.title}</span>
                        </p>
                        <p>
                            Lesson description:{' '}
                            <span>{lesson.description}</span>
                        </p>
                    </div>
                </div>

                {lastVisitedPage && lastProgress < 100 ? (
                    <>
                        <button
                            className="btn btn-primary mt-3"
                            onClick={handleContinueLesson}
                        >
                            Continue Lesson
                        </button>
                        <button
                            className="btn btn-secondary mt-3"
                            onClick={handleRestartLesson}
                        >
                            Start Lesson from Beginning
                        </button>
                    </>
                ) : (
                    <button className="btn" onClick={handleRestartLesson}>
                        Start Lesson
                    </button>
                )}
            </div>
        </Layout>
    );
};

export default ChosenLesson;
