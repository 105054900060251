import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    useGetQuestionIdLanguages,
    useTestCode,
    useCreateProgressTracking,
    useGetLessonsById,
    useCompleteLesson,
    useGetProgressTracking,
} from '../../hooks';
import Layout from '../../components/layout/Layout/Layout';
import { Formik, Form, Field } from 'formik';
import { EditorView, basicSetup } from 'codemirror';
import { EditorState } from '@codemirror/state';
import { python } from '@codemirror/lang-python';
import { cpp } from '@codemirror/lang-cpp';
import { java } from '@codemirror/lang-java';
import * as Yup from 'yup';
import './pageChosen.scss';

const PageChosen = () => {
    const urlparams = useParams(); // Dobijamo lessonId i pageId iz URL-a
    //console.log('urlparams:', urlparams);
    const navigate = useNavigate();
    const { data: lesson } = useGetLessonsById(urlparams.id);
    const { data: progress } = useGetProgressTracking();
    //console.log('lesson:', lesson);
    const [currentPageArrayIndex, setCurrentPageArrayIndex] = useState(0);
    const page = lesson?.pages[currentPageArrayIndex] || {};

    useEffect(() => {
        if (lesson && progress) {
            for (let i = 0; i < lesson.pages.length; i++) {
                if (lesson.pages[i].id == urlparams.pageid) {
                    setCurrentPageArrayIndex(i);
                    //console.log('==== ', lesson.pages[i].id);
                    //console.log('==== ', urlparams.pageid);
                    //console.log('==== ', currentPageArrayIndex);
                    break;
                }
            }
            //ovo se iz nekog razloga dva put vrti
        }
    }, [lesson, progress, urlparams]);

    const runCode = useTestCode();
    const createProgressTracking = useCreateProgressTracking();
    const completeLesson = useCompleteLesson();
    const [testResults, setTestResults] = useState(null);

    const questionId =
        page.type === 'program' && page.questions && page.questions.length > 0
            ? page.questions[0].id
            : null;

    const { data: languages, isLoading: languagesLoading } =
        useGetQuestionIdLanguages(questionId, { enabled: !!questionId });

    const [initialValues, setInitialValues] = useState({
        language: '',
        code: '\n\n\n\n\n', // Default 5 praznih redova
    });

    useEffect(() => {
        if (page.type === 'program' && languages && languages.length > 0) {
            setInitialValues({
                language: languages[0].name,
                code: '\n\n\n\n\n', // Default 5 praznih redova
            });
        }
    }, [page.type, languages]);

    const validationSchema = Yup.object({
        language: Yup.string().required('Required'),
        code: Yup.string().required('Required'),
    });

    const handlePageSubmit = (values, page) => {
        const selectedLanguage = languages.find(
            (lang) => lang.name === values.language
        );

        const languageId = selectedLanguage ? selectedLanguage.id : null;

        if (!languageId) {
            console.error('Selected language not found');
            return;
        }

        runCode.mutate(
            {
                formValues: {
                    question_id: questionId,
                    language_id: languageId,
                    code: values.code,
                },
            },
            {
                onSuccess: (data) => {
                    setTestResults(data.tests);
                },
                onError: (error) => {
                    console.error('Error running code:', error);
                },
            }
        );
    };

    const handleNextPage = () => {
        if (currentPageArrayIndex === lesson.pages.length - 1) {
            //console.log('No next page available');
            return;
        }
        let nextPageArrayIndex = currentPageArrayIndex + 1;
        const nextPageId = lesson.pages[nextPageArrayIndex].id;
        ////console.log('nextpagearrid:', nextPageArrayIndex);

        if (nextPageArrayIndex !== null) {
            createProgressTracking.mutate(
                { page_id: nextPageId },
                {
                    onSuccess: () => {
                        navigate(`/lessons/${lesson.id}/${nextPageId}`);
                    },
                    onError: (error) => {
                        console.error(
                            'Error updating progress tracking:',
                            error
                        );
                    },
                }
            );
        } else {
            //console.log('No next page available');
        }
    };

    const handlePreviousPage = () => {
        if (currentPageArrayIndex <= 0) {
            //console.log('No previous page available');
            return;
        }
        let previousPageArrayIndex = currentPageArrayIndex - 1;
        const previousPageId = lesson.pages[previousPageArrayIndex].id;
        //console.log('previouspagearrid:', previousPageId);

        if (previousPageArrayIndex !== null) {
            createProgressTracking.mutate(
                { page_id: page.id },
                {
                    onSuccess: () => {
                        navigate(`/lessons/${lesson.id}/${previousPageId}`);
                    },
                    onError: (error) => {
                        console.error(
                            'Error updating progress tracking:',
                            error
                        );
                    },
                }
            );
        } else {
            //console.log('No next page available');
        }
    };

    const handleFinishLesson = async () => {
        try {
            await createProgressTracking.mutateAsync({ page_id: page.id });
            await completeLesson.mutateAsync({ lesson_id: lesson.id });
            navigate(`/lessons`);
        } catch (error) {
            console.error('Error completing lesson:', error);
        }
    };

    if (languagesLoading || !lesson) {
        return <div>Loading...</div>;
    }

    return (
        <Layout>
            <div className="container mt-5 current-lesson">
                {page.type === 'program' ? (
                    <div className="card shadow-sm">
                        <div className="card-header text-white">
                            <h2>Program: {page.title}</h2>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(values) =>
                                    handlePageSubmit(values, page)
                                }
                                enableReinitialize
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    setFieldValue,
                                }) => (
                                    <Form>
                                        <div className="mb-3">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: page.questions[0].question_text,
                                                }}
                                            />
                                            <label
                                                htmlFor="language"
                                                className="form-label"
                                            >
                                                Choose Language:
                                            </label>
                                            <Field
                                                as="select"
                                                name="language"
                                                className={`form-select ${
                                                    errors.language &&
                                                    touched.language
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        'language',
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                {languages.map((lang) => (
                                                    <option
                                                        key={lang.id}
                                                        value={lang.name}
                                                    >
                                                        {lang.name}
                                                    </option>
                                                ))}
                                            </Field>
                                            {errors.language &&
                                                touched.language && (
                                                    <div className="invalid-feedback">
                                                        {errors.language}
                                                    </div>
                                                )}
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                htmlFor="code"
                                                className="form-label"
                                            >
                                                Enter Code:
                                            </label>
                                            <CodeMirrorWrapper
                                                value={values.code}
                                                language={values.language}
                                                onChange={(value) =>
                                                    setFieldValue('code', value)
                                                }
                                                className={`border ${
                                                    errors.code && touched.code
                                                        ? 'border-danger'
                                                        : 'border-secondary'
                                                } rounded`}
                                            />
                                            {errors.code && touched.code && (
                                                <div className="text-danger mt-2">
                                                    {errors.code}
                                                </div>
                                            )}
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-success w-100"
                                        >
                                            Run Code
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                ) : (
                    <div className="card shadow-sm">
                        <div className="card-header bg-secondary text-white">
                            <h2>{page.title}</h2>
                        </div>
                        <div className="card-body">
                            {page.content[0] === undefined ? (
                                'Loading...'
                            ) : (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: page.content[0].content,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )}
                {testResults && (
                    <div className="card mt-4 shadow-sm">
                        <div className="card-header bg-success text-white">
                            <h2>Test Results</h2>
                        </div>
                        <div className="card-body">
                            {testResults.map((test, index) => (
                                <div key={index} className="mb-3">
                                    <h5 className="text-primary">
                                        {test.name}
                                    </h5>
                                    <p>
                                        <strong>Correct:</strong>{' '}
                                        {test.correct ? 'Yes' : 'No'}
                                    </p>
                                    {test.example && (
                                        <>
                                            <p>
                                                <strong>Input:</strong>{' '}
                                                {test.input}
                                            </p>
                                            <p>
                                                <strong>Output:</strong>{' '}
                                                {test.output}
                                            </p>
                                        </>
                                    )}
                                    {!test.correct && test.reason && (
                                        <p className="text-danger">
                                            <strong>Reason:</strong>{' '}
                                            {test.reason}
                                        </p>
                                    )}
                                    <hr />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="d-flex justify-content-between mt-3">
                    {currentPageArrayIndex > 0 && (
                        <button
                            className="btn btn-secondary"
                            onClick={handlePreviousPage}
                        >
                            Previous Page
                        </button>
                    )}
                    <div className="ms-auto">
                        {currentPageArrayIndex === lesson.pages.length - 1 ? (
                            <button
                                className="btn btn-success me-2"
                                onClick={handleFinishLesson}
                            >
                                Finish Lesson
                            </button>
                        ) : (
                            <button
                                className="btn btn-primary me-2"
                                onClick={handleNextPage}
                            >
                                Next Page
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const CodeMirrorWrapper = ({ value, language, onChange, className }) => {
    const editorRef = useRef(null);
    const editorViewRef = useRef(null);

    const getCodeMirrorExtension = (language) => {
        switch (language.toLowerCase()) {
            case 'python':
                return python();
            case 'c':
            case 'cpp':
                return cpp();
            case 'java':
                return java();
            default:
                return [];
        }
    };

    useEffect(() => {
        if (editorRef.current) {
            if (editorViewRef.current) {
                editorViewRef.current.destroy();
            }

            const view = new EditorView({
                state: EditorState.create({
                    doc: value,
                    extensions: [
                        basicSetup,
                        getCodeMirrorExtension(language),
                        EditorView.theme({
                            '&': {
                                backgroundColor: '#fff',
                                color: '#000',
                            },
                            '.cm-content': {
                                caretColor: '#000',
                            },
                        }),
                        EditorView.updateListener.of((update) => {
                            if (update.changes) {
                                onChange(update.state.doc.toString());
                            }
                        }),
                    ],
                }),
                parent: editorRef.current,
            });

            editorViewRef.current = view;
        }

        return () => {
            if (editorViewRef.current) {
                editorViewRef.current.destroy();
                editorViewRef.current = null;
            }
        };
    }, [language]);

    return <div ref={editorRef} className={`p-2 ${className}`}></div>;
};

export default PageChosen;
