import { Formik, useFormik, Field, Form, ErrorMessage } from 'formik';
import React from 'react';
import adminStyles from './adminPageCss/usersStyles.module.css';
import { useUpdateUser } from './putUsersForAdmin';
import * as Yup from 'yup';
import style from './adminPageCss/leftNavbar.module.css';

export default function ManageUsersForAdmin({
    users,
    institut,
    myUpdateAddUserInstitut,
    institutSelected,
}) {
    const deleteUser = (userID) => {};

    return (
        <div
            className={`col-10 overflow-y-auto mb-5 ${style.usersBackgroundColor} fw-bold`}
        >
            <h1 className="text-center">{institutSelected}</h1>
            <div className="prikaziInstitucije p-2 d-flex flex-column overflow-y-auto">
                {users
                    .filter((user) =>
                        user.institutions.includes(institutSelected)
                    )
                    .map((user) => (
                        <div
                            className={`d-flex justify-content-around text-center align-items-center ${adminStyles.heightUsers}`}
                            style={{ border: '1.4px #BDC3C7  solid' }}
                            key={`userID-${user.id}`}
                        >
                            <div>
                                <span>
                                    Username: {user.username || 'Nepoznato'}
                                </span>
                            </div>
                            <div>
                                <span>
                                    Ime: {user.first_name || 'Nepoznato'}
                                </span>
                            </div>
                            <div>Prezime: {user.last_name || 'Nepoznato'}</div>


                        {/*
                            <button className="btn bg-danger" onClick={() => deleteUser(user.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                </svg> 

                                <span>Delete</span>
                            </button>
                         */}

                        </div>
                    ))}

                <div className="d-flex">
                    <button
                        className="btn btn-primary mt-5"
                        onClick={() => myUpdateAddUserInstitut('')}
                        style={{ width: '14%' }}
                    >
                        <span>Izađi</span>
                    </button>
                </div>
            </div>
        </div>
    );
}
