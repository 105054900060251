import { useAxios } from '../../hooks/useAxios';
import { useQuery } from 'react-query';

const useGetUsersForAdmin = () => {
    const axios = useAxios();

    const getAdminUsers = async () => {
        try {
            const { data } = await axios.get(`/admin/users/`);

            return data;
        } catch (error) {
            console.error('Error fetching users for admin:', error);
            throw new Error(error.message);
        }
    };

    return useQuery(['usersForAdmin'], getAdminUsers, {
        onSuccess: (data) => {
            console.log('Data fetched successfully:', data);
        },
        onError: (error) => {
            console.error('Error fetching users for admin:', error);
        },
    });
};

export { useGetUsersForAdmin };
