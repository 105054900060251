import { useAxios } from '../useAxios';
import { useQuery } from 'react-query';

const useGetQuestionIdLanguages = (questionid) => {
    const axios = useAxios();

    const getQuestionIdLanguages = async () => {
        try {
            const { data } = await axios.get(
                `/question/${questionid}/languages/`
            );
            return data;
        } catch (error) {
            console.error('Error fetching languages:', error);
            throw new Error(error.message);
        }
    };

    return useQuery(['languages', questionid], getQuestionIdLanguages, {
        enabled: !!questionid,
        onSuccess: (data) => {},
        onError: (error) => {
            console.error('Error fetching languages:', error);
        },
    });
};

export { useGetQuestionIdLanguages };
