import React from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
} from 'react-router-dom';
import Layout from '../components/layout/Layout/Layout.jsx';
import {
    Login,
    Registration,
    Lessons,
    ChosenLesson,
    InstructorLessons,
    EditInstructorLesson,
    PageChosen,
    AdminMain,
    ViewProfile,
} from '../pages/index.js';
import { AuthorizedRoutes } from './AuthorizedRoutes.jsx';
import Homepage from '../pages/homepage/Homepage.jsx';
import OAuthHandler from '../components/OAuthHandler.jsx';

const AppRouter = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route
                path="/"
                element={<Layout children={<Homepage></Homepage>} />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Registration />} />
            <Route
                path="/login-with-google"
                element={<OAuthHandler type="google" />}
            />
            <Route
                path="/login-with-microsoft"
                element={<OAuthHandler type="microsoft" />}
            />

            <Route element={<AuthorizedRoutes authorizedRoles={['ucenik']} />}>
                <Route path="/lessons" element={<Lessons />} />
                <Route path="/lessons/:id" element={<ChosenLesson />} />
                <Route path="/lessons/:id/:pageid" element={<PageChosen />} />
                <Route path="/profile" element={<ViewProfile />} />
                <Route
                    path="/instructor-lessons"
                    element={<InstructorLessons />}
                />
                adminDashboard
                <Route
                    path="/instructor-lessons/:id"
                    element={<EditInstructorLesson />}
                />
                <Route path="/adminDashboard" element={<AdminMain />} />
            </Route>
        </>
    )
);
export default AppRouter;
