import { useAxios } from '../useAxios';
import { useMutation, useQueryClient } from 'react-query';

const useCreateQuestion = () => {
    const axios = useAxios();
    const queryClient = useQueryClient();

    const createQuestion = async (formValues) => {
        try {
            const { data } = await axios.post(`/question/`, formValues);

            return data;
        } catch (error) {
            console.error('Error creating question', error);
            throw new Error(error.message);
        }
    };

    return useMutation(createQuestion, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['lessons'],
            });
        },
        onError: (error) => {
            console.error('Error creating question', error);
        },
    });
};

export { useCreateQuestion };
