import { useAxios } from '../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

const useCreateInstitution = () => {
    const axios = useAxios();
    const navigate = useNavigate();
    const queryClient = useQueryClient();


    const createInstitution = async (formValues) => {
        try {
            const { data } = await axios.post(
                `/admin/institution/`,
                formValues
            );

            return data;
        } catch (error) {
            console.error('Error fetching institution:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(createInstitution, {
        onSuccess: (data) => {
            //navigate(`/instructor-lessons/${data.id}`);
            queryClient.invalidateQueries({ queryKey: ['institutionsForAdmin'] })
            console.log('Data fetched successfully:', data);
        },
        onError: (error) => {
            console.error('Error fetching institution:', error);
        },
    });
};

export { useCreateInstitution };
