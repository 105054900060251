import React, { useState } from 'react';
import { useChatBot, useChatBotInitially } from '../../hooks';
import './chatbot.scss';

const Chatbot = () => {
    const [input, setInput] = useState('');
    const [prevMessages, setPrevMessages] = useState(['How can i help you?']);
    const chatbot = useChatBot();
    const chatbotInitially = useChatBotInitially();
    const [threadId, setThreadId] = useState();
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const messageChatbot = (e) => {
        e.preventDefault();
        if (input.length === 0) return;
        setPrevMessages((prevPrevMessages) => [...prevPrevMessages, input]);
        setInput('');
        setIsLoading(true);
        if (prevMessages.length === 1) {
            chatbotInitially.mutate(
                { message: input },
                {
                    onSuccess: (message) => {
                        setPrevMessages((prevPrevMessages) => [
                            ...prevPrevMessages,
                            message.message[0].text.value,
                        ]);
                        setThreadId(message.id);
                        setIsLoading(false);
                    },
                }
            );
        } else {
            chatbot.mutate(
                { message: input, id: threadId },
                {
                    onSuccess: (message) => {
                        setPrevMessages((prevPrevMessages) => [
                            ...prevPrevMessages,
                            message.message[0].text.value,
                        ]);
                        setIsLoading(false);
                    },
                }
            );
        }
    };

    return (
        <div
            className={`chatbot-container ${
                isChatbotOpen ? '' : 'chatbot-closed'
            }`}
            onClick={() => {
                if (!isChatbotOpen) {
                    setIsChatbotOpen(true);
                }
            }}
        >
            <div className="name-container">
                <p>Chatbot</p>
                {isChatbotOpen ? (
                    <div
                        className="exit-button"
                        onClick={() => setIsChatbotOpen(false)}
                    >
                        X
                    </div>
                ) : null}
            </div>
            <div className="messages">
                {prevMessages.map((message, index) => {
                    if (index % 2 == 1) {
                        return <p className="chatbot-message">{message}</p>;
                    }
                    return <p className="my-message">{message}</p>;
                })}
                {isLoading ? <p className="thinking">thinking...</p> : null}
            </div>
            <form onSubmit={(e) => messageChatbot(e)}>
                <div className="input">
                    <textarea
                        name="input"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                    />
                    <button type="submit">{'>'}</button>
                </div>
            </form>
        </div>
    );
};

export { Chatbot };
