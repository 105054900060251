import { useAxios } from '../useAxios';
import { useMutation, useQueryClient } from 'react-query';

const useUpdateTest = () => {
    const axios = useAxios();
    const queryClient = useQueryClient();

    const updateTest = async (formValues) => {
        try {
            const { data } = await axios.put(
                `/test/${formValues.id}/`,
                formValues
            );

            return data;
        } catch (error) {
            console.error('Error update test', error);
            throw new Error(error.message);
        }
    };

    return useMutation(updateTest, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['lessons'],
            });
        },
        onError: (error) => {
            console.error('Error update test', error);
        },
    });
};

export { useUpdateTest };
