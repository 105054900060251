import { useAxios } from '../../hooks/useAxios';
import { useQuery } from 'react-query';

const useGetInstitutionsForAdmin = () => {
    const axios = useAxios();

    const getAdminInstitutions = async () => {
        try {
            const { data } = await axios.get(`/admin/institutions/`);

            return data;
        } catch (error) {
            console.error('Error fetching institutions for admin:', error);
            throw new Error(error.message);
        }
    };

    return useQuery(['institutionsForAdmin'], getAdminInstitutions, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.error('Error fetching institutions for admin:', error);
        },
    });
};

export { useGetInstitutionsForAdmin };
