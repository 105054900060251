import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useStandardRegistration } from '../../../hooks';
import '../form.scss';
import {
    GoogleLoginButton,
    MicrosoftLoginButton,
} from 'react-social-login-buttons';

// Custom validation function for the password
const validatePassword = (value, otherValues) => {
    let errors = [];

    // 1. Password can't be similar to username or email
    if (
        otherValues &&
        (value.includes(otherValues.username) ||
            value.includes(otherValues.email))
    ) {
        errors.push(
            'Your password can’t be too similar to your other personal information.'
        );
    }

    // 2. Must be at least 8 characters
    if (value.length < 8) {
        errors.push('Your password must contain at least 8 characters.');
    }

    // 3. Can't be entirely numeric
    if (/^\d+$/.test(value)) {
        errors.push('Your password can’t be entirely numeric.');
    }

    return errors.length ? errors.join(' ') : undefined;
};

// Validation schema with Yup
const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    username: Yup.string()
        .min(3, 'Must be at least 3 characters')
        .required('Required'),
    password: Yup.string()
        .test(
            'passwordValidation',
            'Password does not meet requirements',
            function (value) {
                return validatePassword(value, this.parent) === undefined;
            }
        )
        .required('Required'),
    passwordReenter: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
});

const Registration = () => {
    const standardRegistration = useStandardRegistration();

    return (
        <div className="register-form-container">
            <h1>Register</h1>
            <Formik
                initialValues={{
                    email: '',
                    username: '',
                    password: '',
                    passwordReenter: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    const { passwordReenter, ...formValues } = values;
                    standardRegistration.mutate(formValues);
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className="form-group">
                            <label>Email:</label>
                            <Field
                                name="email"
                                type="email"
                                className="form-control"
                            />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="error"
                            />
                        </div>

                        <div className="form-group">
                            <label>Username:</label>
                            <Field
                                name="username"
                                type="text"
                                className="form-control"
                            />
                            <ErrorMessage
                                name="username"
                                component="div"
                                className="error"
                            />
                        </div>

                        <div className="form-group">
                            <label>Password:</label>
                            <Field
                                name="password"
                                type="password"
                                className="form-control"
                            />
                            <ErrorMessage
                                name="password"
                                component="div"
                                className="error"
                            />
                        </div>

                        <div className="form-group">
                            <label>Re-enter Password:</label>
                            <Field
                                name="passwordReenter"
                                type="password"
                                className="form-control"
                            />
                            <ErrorMessage
                                name="passwordReenter"
                                component="div"
                                className="error"
                            />
                        </div>

                        <button type="submit" className="submit-btn">
                            Register
                        </button>
                    </Form>
                )}
            </Formik>
            <a
                href={`https://api.djangopasta.live/api/google/login`}
                className="google-button"
            >
                <GoogleLoginButton />
            </a>

            <a
                href={`https://api.djangopasta.live/api/microsoft/login`}
                className="google-button"
            >
                <MicrosoftLoginButton />
            </a>
        </div>
    );
};

export default Registration;
