import React from 'react';
import { useGetUsersForAdmin } from './getUsersForAdmin';
import AdminSidebar from './AdminSidebar';
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';
import { useState } from 'react';
import AdminInstitutions from './AdminInstitutions';
import AdminUsers from './AdminUsers';
import { useGetInstitutionsForAdmin } from './getInstitutionsForAdmin';
import adminStyles from './adminPageCss/usersStyles.module.css';
import ManageUsersForAdmin from './ManageUsersForAdmin';

export default function AdminMain() {
    const [usersMode, updateUsersMode] = useState(true);

    const { data: users } = useGetUsersForAdmin();
    const { data: instituts } = useGetInstitutionsForAdmin();

    if (!users || !instituts) {
        return <p>Loading...</p>;
    }

    /*
    const myus = [
        {
            id: 1,
            username: 'marko',
            email: 'marko@gmail.com',
            institutions: [1],
        },
        {
            id: 2,
            username: 'newuser',
            email: 'newuser@gmail.com',
            institutions: [3],
        },
        {
            id: 3,
            username: 'newuserInst2',
            email: 'newuserInst2@gmail.com',
            institutions: [2, 1],
        },
        {
            id: 4,
            username: 'commonUser',
            email: 'commonUser@gmail.com',
            institutions: [1],
        },
        {
            id: 5,
            username: 'newInstructorInst2',
            email: 'newInstructorInst2@gmail.com',
            institutions: [1],
        },
        {
            id: 6,
            username: 'antonio',
            email: 'antonio@gmail.com',
            institutions: [3],
        },
        {
            id: 7,
            username: 'antony123',
            email: 'antony@gmail.com',
            first_name: '',
            last_name: '',
            role: 'admin',
            institutions: [2],
        },
        {
            id: 8,
            username: 'prof_admin',
            email: 'prof_admin@gmail.com',
            first_name: '',
            last_name: '',
            role: 'admin',
            institutions: [1],
        },
    ];

    */
    
    
    console.log(users);
    


    return (
        <>

            <div className="container-fluid vh-75" style={{height: "80vh"}}>
                <div className={`row flex-nowrap h-100 ${adminStyles.usersBackgroundColor}`}>
                    <AdminSidebar updateUsersMode={updateUsersMode}/>
                      {usersMode ? <AdminUsers users={users} instituts={instituts}/>
                                 : <AdminInstitutions instituts={instituts} users={users}/>
                              }

                </div>
            </div>
        </>
    );
}
