import React from 'react';
import { useQuery } from 'react-query';
import {
    useGetUserBadgesList,
    useGetProgressTracking,
    useGetLessons,
} from '../../hooks';
import { useUser } from '../../context/userContext';
import Layout from '../../components/layout/Layout/Layout';
import './ViewProfile.scss';

const ViewProfile = () => {
    const { user } = useUser();
    const {
        data: badges,
        isLoading: badgesLoading,
        error: badgesError,
    } = useGetUserBadgesList();
    const {
        data: progressTracking,
        isLoading: progressLoading,
        error: progressError,
    } = useGetProgressTracking();
    const {
        data: lessons,
        isLoading: lessonsLoading,
        error: lessonsError,
    } = useGetLessons();

    if (badgesLoading || progressLoading || lessonsLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (badgesError || progressError || lessonsError) {
        return (
            <div className="alert alert-danger text-center mt-5">
                Error loading data. Please try again later.
            </div>
        );
    }

    return (
        <Layout>
            <div className="container mt-5 profile-page">
                {/* User Profile Section */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header text-white">
                        <h2 className="mb-0">User Profile</h2>
                    </div>
                    <div className="card-body">
                        <p>
                            <strong>Username:</strong> {user.username}
                        </p>
                        <p>
                            <strong>Email:</strong> {user.email}
                        </p>
                        <p>
                            <strong>Role:</strong> {user.role}
                        </p>
                    </div>
                </div>

                {/* User Progress Section */}
                <div className="card shadow-sm mb-4">
                    <div className="card-header text-white">
                        <h2 className="mb-0">User Progress</h2>
                    </div>
                    <div className="card-body">
                        <div className="row g-4">
                            {progressTracking.map((progress) => {
                                const lesson = lessons.find(
                                    (lesson) => lesson.id === progress.lesson_id
                                );
                                const badge = badges.find(
                                    (badge) =>
                                        badge.lesson_id === progress.lesson_id
                                );

                                return (
                                    <div
                                        className="col-12 col-md-6 col-lg-4"
                                        key={progress.lesson_id}
                                    >
                                        <div className="card h-100">
                                            <div className="card-body text-center progress-card">
                                                {progress.progress === 100 &&
                                                    badge && (
                                                        <img
                                                            src={
                                                                badge.image_url
                                                            }
                                                            alt={badge.name}
                                                            className="img-fluid mb-3"
                                                            style={{
                                                                maxWidth:
                                                                    '100px',
                                                                maxHeight:
                                                                    '100px',
                                                            }}
                                                        />
                                                    )}
                                                <h5 className="card-title">
                                                    {lesson
                                                        ? lesson.title
                                                        : 'Loading...'}
                                                </h5>
                                                <p className="card-text">
                                                    <strong>Progress:</strong>{' '}
                                                    {Math.round(
                                                        progress.progress * 100
                                                    ) / 100}
                                                    %
                                                </p>
                                                {/* Progress Bar */}
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                            width: `${
                                                                Math.round(
                                                                    progress.progress *
                                                                        100
                                                                ) / 100
                                                            }%`,
                                                        }}
                                                        aria-valuenow={
                                                            Math.round(
                                                                progress.progress *
                                                                    100
                                                            ) / 100
                                                        }
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    >
                                                        {Math.round(
                                                            progress.progress *
                                                                100
                                                        ) / 100}
                                                        %
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {/* User Badges Section */}
                <div className="card shadow-sm">
                    <div className="card-header text-white">
                        <h2 className="mb-0">User Badges</h2>
                    </div>
                    <div className="card-body">
                        <div className="d-flex flex-wrap">
                            {badges.map((badge) => (
                                <div
                                    key={badge.id}
                                    className="d-flex align-items-center me-4 mb-3"
                                >
                                    <img
                                        src={badge.image_url}
                                        alt={badge.name}
                                        className="img-fluid me-2"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                        }}
                                    />
                                    <span>{badge.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ViewProfile;
